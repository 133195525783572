import { v4 as uuidv4 } from "uuid";
import {
  Citation,
  DeleteCitationResponse,
  Department,
  SearchDocName,
  SimpleUser,
} from "../../types";
import { useAuthInfo } from "@propelauth/react";
import { ReactNode, useContext, useEffect, useState } from "react";
import {
  addCitation,
  deleteCitation,
  generateCitations,
} from "../../utils/apiCalls";
import { toast } from "sonner";
import { cn } from "../../shadcn/lib/utils";
import { Button } from "../../shadcn/components/button";
import {
  CopyIcon,
  Pencil1Icon,
  PlusIcon,
  ReloadIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../shadcn/components/alert-dialog";
import { TimeAgo } from "../../utils/format";
import { NewCitation } from "./NewCitationDialog";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { SparkleIcon } from "lucide-react";
import { PdfCitation } from "../PdfViewer/PdfHighlighter/types";

export const UserMetadata = (props: {
  user: SimpleUser;
  createdAt: string;
}) => {
  return (
    <div className="flex w-full items-center text-xs text-muted-foreground justify-between">
      <div>{`${props.user.first_name} ${props.user.last_name[0]}`}</div>
      <div>
        <TimeAgo timestamp={props.createdAt} />
      </div>
    </div>
  );
};

const DeleteCitation = (props: {
  citationId: string;
  removeCitation: (citationId: string) => void;
  deleteLoading: boolean;
}) => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  return (
    <AlertDialog open={alertOpen} onOpenChange={setAlertOpen}>
      <AlertDialogTrigger asChild>
        <Button size="icon" variant="destructive">
          <TrashIcon className="w-4 h-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you wish to delete the citation?
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant="destructive"
            onClick={() => {
              props.removeCitation(props.citationId);
              setAlertOpen(false);
            }}
            disabled={props.deleteLoading}
          >
            Delete{" "}
            {props.deleteLoading && (
              <ReloadIcon className="w-4 h-4 ml-2 animate-spin" />
            )}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export const IndividualCitationView = (props: {
  persistUrl?: {
    prefix: string;
    suffix: string;
  };
  citation: Citation;
  active: boolean;
  onDeleteSuccess: (citation: Citation) => void;
  onClick: (citationId: string | null) => void;
  onEditClick?: (citation: Citation) => void;
  simpleView?: boolean;
}) => {
  const authInfo = useAuthInfo();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const textToDisplay = props.citation.formatted_text ?? props.citation.text;

  const removeCitation = async () => {
    setDeleteLoading(true);
    let response: DeleteCitationResponse | null;
    if (props.persistUrl) {
      response = await deleteCitation(
        `${props.persistUrl.prefix}/citation/${props.persistUrl.suffix}`,
        props.citation.id!,
        authInfo.accessToken ?? null
      );
    } else {
      response = { source_text: null };
    }
    if (response !== null) {
      props.onDeleteSuccess(props.citation);
    } else {
      toast.error("Failed to delete citation");
    }
    setDeleteLoading(false);
  };

  return (
    <div
      className={cn(
        "p-5 rounded-md space-y-5",
        props.active
          ? "bg-gray-300"
          : "bg-white hover:bg-gray-200 cursor-pointer"
      )}
      onClick={() => {
        props.onClick(props.citation.id);
      }}
    >
      <div className="flex justify-between">
        <div className="w-[calc(100%-120px)]">
          <div className="font-semibold text-sm">{props.citation.doc_name}</div>
          <div className="text-gray-500 text-xs">
            {textToDisplay.slice(0, 100)}...
          </div>
        </div>
        <div className="flex items-center space-x-4">
          {props.onEditClick && (
            <Button
              variant="outline"
              onClick={(e) => {
                e.stopPropagation();
                if (props.onEditClick) {
                  props.onEditClick(props.citation);
                }
              }}
              size="icon"
            >
              <Pencil1Icon className="w-4 h-4 text-gray-500" />
            </Button>
          )}
          <DeleteCitation
            citationId={props.citation.id!}
            removeCitation={removeCitation}
            deleteLoading={deleteLoading}
          />
        </div>
      </div>
      {props.citation.user &&
        props.citation.created_at &&
        !props.simpleView && (
          <UserMetadata
            user={props.citation.user}
            createdAt={props.citation.created_at}
          />
        )}
    </div>
  );
};

export const CitationView = (props: {
  relevantDocs: SearchDocName[];
  allowedDocTypeIds: string[];
  headerChildren: React.ReactNode;
  question: string;
  citations: Citation[];
  activeCitationId: string | null;
  setActiveCitationId: React.Dispatch<React.SetStateAction<string | null>>;
  onNewCitationSuccess: (
    citation: Citation,
    existingCitation: Citation | null
  ) => void;
  onDeleteCitationSuccess: (citation: Citation) => void;
  setDoc: (activeCitation: Citation | null) => void;
  labelText: string;
  onClickGenerate?: (citations: Citation[], departments: Department[]) => void;
  hideAtlasWidget?: boolean;
  persistUrl?: {
    prefix: string;
    suffix: string;
  };
  generateUrl?: {
    prefix: string;
    suffix: string;
  };
  simpleView?: boolean;
  docTypeSelector?: ReactNode;
}) => {
  const authInfo = useAuthInfo();
  const { citations, setCitations, setPageNumber } =
    useContext(DocViewerContext);
  const [activeCitation, setActiveCitation] = useState<Citation | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [existingCitation, setExistingCitation] = useState<Citation>();
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);

  const createPdfCitations = (citaitons: Citation[]): PdfCitation[] => {
    return citaitons.map((citation) => {
      if (citation.id === props.activeCitationId) {
        setActiveCitation(citation);
      }
      const isSelected = citation.id === props.activeCitationId;
      return {
        id: citation.id || `cit-${citation.text.slice(0, 14)}`,
        match: citation.text,
        exactMatch: true,
        page: citation.page,
        isSelected: isSelected,
        className: `bg-highlight-${isSelected ? "default" : "inactive"}`,
        onClick: () => {
          props.setActiveCitationId(citation.id);
        },
      };
    });
  };
  useEffect(() => {
    setCitations(createPdfCitations(props.citations));
  }, [props.citations, props.activeCitationId]);

  const copyCitationsToClipboard = () => {
    let text = "";
    if (props.citations.length > 0) {
      props.citations.forEach((citation) => {
        if (!text.includes(`Document: ${citation.doc_name}`)) {
          text += `Document: ${citation.doc_name}\n`;
        }
      });
    }
    navigator.clipboard.writeText(text);
    toast.success("Copied!");
  };

  const onSuccess = (citation: Citation) => {
    props.setActiveCitationId(citation.id);
    props.onNewCitationSuccess(citation, existingCitation ?? null);
  };

  const saveClick = async (citation: Citation) => {
    if (props.persistUrl) {
      const response = await addCitation(
        `${props.persistUrl.prefix}/citation/${props.persistUrl.suffix}`,
        citation,
        authInfo.accessToken ?? null
      );
      return response;
    } else {
      return Promise.resolve({
        citation_ids: [citation.id ?? uuidv4()],
        departments: [],
      });
    }
  };

  const generateClick = async () => {
    if (props.onClickGenerate && props.generateUrl) {
      let generatedCitationsLength = 0;
      setGenerateLoading(true);
      try {
        for await (const output of generateCitations(
          `${props.generateUrl.prefix}/citation-generate/${props.generateUrl.suffix}`,
          props.allowedDocTypeIds,
          authInfo.accessToken ?? null
        )) {
          props.onClickGenerate(output.citations, output.departments);
          if (activeCitation?.id !== output.citations[0].id) {
            props.setActiveCitationId(output.citations[0].id);
          }
          generatedCitationsLength = output.citations.length;
        }
        if (generatedCitationsLength === 0) {
          toast.info("No suggestions at the moment");
        }
      } catch (error: any) {
        toast.error("Failed to generate citations");
      } finally {
        setGenerateLoading(false);
      }
    }
  };

  const updateActiveCitationDisplay = () => {
    if (activeCitation) {
      props.setDoc(activeCitation);
      setPageNumber(activeCitation.page);
    } else {
      props.setDoc(null);
      setPageNumber(1);
    }
  };

  useEffect(() => {
    updateActiveCitationDisplay();
  }, [activeCitation?.id]);

  return (
    <div className="space-y-2">
      <div className="flex justify-between">
        <div className="font-semibold text-lg">{props.labelText}</div>
        <div className="flex items-center space-x-2">
          {props.docTypeSelector}
          <NewCitation
            hideAtlasWidget={props.hideAtlasWidget}
            open={dialogOpen}
            saveClick={saveClick}
            setOpen={setDialogOpen}
            title={existingCitation ? "Edit Citation" : "New Citation"}
            question={props.question}
            existingCitation={existingCitation}
            successCallback={onSuccess}
            relevantDocs={props.relevantDocs}
            onCancel={() => {
              updateActiveCitationDisplay();
            }}
            allowedDocTypeIds={props.allowedDocTypeIds}
            headerChildren={props.headerChildren}
          />
          {props.onClickGenerate && (
            <Button
              variant="secondary"
              size="sm"
              onClick={generateClick}
              disabled={generateLoading || props.allowedDocTypeIds.length === 0}
            >
              {generateLoading ? (
                <ReloadIcon className="w-4 h-4 mr-2 animate-spin" />
              ) : (
                <SparkleIcon className="w-4 h-4 mr-2" />
              )}
              Suggest Citations
            </Button>
          )}
          <Button
            variant="outline"
            onClick={() => {
              setExistingCitation(undefined);
              setDialogOpen(true);
            }}
            className="text-sm"
          >
            <PlusIcon className="w-4 h-4 mr-2" />
            Add Citation
          </Button>
          {props.citations.length > 0 && !props.simpleView && (
            <Button variant="outline" onClick={copyCitationsToClipboard}>
              <CopyIcon className="w-4 h-4 mr-2" />
              <span className="text-sm">Copy</span>
            </Button>
          )}
        </div>
      </div>
      {props.simpleView && props.headerChildren}
      <div className="space-y-3">
        {props.citations.map((citation) => (
          <IndividualCitationView
            persistUrl={props.persistUrl}
            key={citation.id}
            citation={citation}
            onClick={() => {
              props.setActiveCitationId(citation.id);
            }}
            active={props.activeCitationId === citation.id}
            onDeleteSuccess={(citation) => {
              props.onDeleteCitationSuccess(citation);
            }}
            onEditClick={(citation) => {
              setExistingCitation(citation);
              setDialogOpen(true);
            }}
            simpleView={props.simpleView}
          />
        ))}
      </div>
    </div>
  );
};
