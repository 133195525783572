import { useAuthInfo } from "@propelauth/react";
import { Layout } from "../../components/Layout";
import { useParams } from "react-router-dom";
import { getQuestions } from "../../utils/apiCalls";
import { useContext, useEffect, useState } from "react";
import { toast } from "sonner";
import {
  AuditQuestionMetadata,
  SimpleUser,
  TaskStatus,
  statusToLabelMap,
} from "../../types";
import { Badge } from "../../shadcn/components/badge";
import { TimeAgo } from "../../utils/format";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../shadcn/components/breadcrumb";
import { Input } from "../../shadcn/components/input";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../shadcn/components/accordion";
import { MultiSelectControl } from "../../components/MultiSelectControl";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { UserContext } from "../../contexts/UserContext";
import { LoadingView } from "../../components/Loading";
import { StatusBadge } from "../../components/StatusSelector";

const BreadcrumbNav = (props: { auditId: string }) => {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href="/audit">Audits</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink href={`/audit/${props.auditId}`}>
            Audit Resources
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Questions</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};

const QuestionCard = (props: { question: AuditQuestionMetadata }) => {
  return (
    <div
      key={props.question.id}
      className="bg-white w-full p-5 rounded-md hover:bg-gray-200 cursor-pointer space-y-4"
    >
      <div className="flex justify-between">
        <div className="flex space-x-2 text-md font-semibold max-w-[90%]">
          <span className="w-6">{props.question.question_index}.</span>
          <span>{props.question.question}</span>
        </div>
      </div>
      <div className="flex justify-between pl-4">
        <div className="flex space-x-2">
          <StatusBadge status={props.question.status} />
          {props.question.assignments.length > 0 && (
            <div className="flex space-x-1">
              {props.question.assignments.length === 1 ? (
                <Badge className="bg-gray-400">
                  {props.question.assignments[0].email}
                </Badge>
              ) : (
                <Tooltip>
                  <TooltipTrigger>
                    <Badge className="bg-gray-400">{`${props.question.assignments.length} assignees`}</Badge>
                  </TooltipTrigger>
                  <TooltipContent>
                    <div className="space-y-1">
                      {props.question.assignments.map((assignment) => (
                        <div className="text-left">{assignment.email}</div>
                      ))}
                    </div>
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
          )}
        </div>
        {props.question.updated_at && (
          <div className="text-sm text-gray-500">
            Last Saved: <TimeAgo timestamp={props.question.updated_at} />
          </div>
        )}
      </div>
    </div>
  );
};

export const AuditQuestionView = () => {
  const { auditId, auditResourceId } = useParams();
  const authInfo = useAuthInfo();
  const { simpleUsers } = useContext(UserContext);
  const [questions, setQuestions] = useState<AuditQuestionMetadata[]>([]);
  const [questionsLoading, setQuestionsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState<TaskStatus[]>([]);
  const [assigneeFilter, setAssigneeFilter] = useState<SimpleUser[]>([]);

  interface QuestionGroup {
    [key: string]: AuditQuestionMetadata[];
  }

  const questionsToDisplay = questions
    .filter(
      (question) =>
        question.question.toLowerCase().includes(search.toLowerCase()) ||
        search === ""
    )
    .filter((question) => {
      return (
        statusFilter.length === 0 || statusFilter.includes(question.status)
      );
    })
    .filter((question) => {
      return (
        assigneeFilter.some((assignee) =>
          question.assignments.some((a) => a.email === assignee.email)
        ) || assigneeFilter.length === 0
      );
    });

  useEffect(() => {
    if (auditResourceId) {
      setQuestionsLoading(true);
      getQuestions(auditResourceId, authInfo.accessToken ?? null).then(
        (questions) => {
          if (questions) {
            setQuestions(questions);
          } else {
            toast.error("Failed to fetch questions");
          }
          setQuestionsLoading(false);
        }
      );
    }
  }, [auditResourceId]);

  const groupQuestionsBySection = questionsToDisplay.reduce<QuestionGroup>(
    (acc, question) => {
      const key = `${question.section_index}.\u00A0\u00A0\u00A0${question.section_title}`;
      acc[key] = acc[key] || [];
      acc[key].push(question);
      return acc;
    },
    {}
  );

  return (
    <Layout pageName="Audits">
      <BreadcrumbNav auditId={auditId ?? ""} />
      <div className="flex justify-center">
        <div className="flex space-x-4 w-[800px] items-center">
          <Input
            className="flex-grow"
            placeholder="Find questions by keywords..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <MultiSelectControl
            title="Status"
            items={["todo", "review", "done"].map((status) => ({
              id: status,
              name: statusToLabelMap[status as TaskStatus],
            }))}
            selectedItems={statusFilter.map((status) => ({
              id: status,
              name: statusToLabelMap[status as TaskStatus],
            }))}
            clearSelectedItems={() => {
              setStatusFilter([]);
            }}
            selectItem={(item, isSelected) =>
              setStatusFilter((prev) => {
                if (isSelected) {
                  return [...prev, item.id as TaskStatus];
                }
                return prev.filter((s) => s !== item.id);
              })
            }
          />
          <MultiSelectControl
            title="Assignee"
            items={simpleUsers.map((u) => ({
              id: u.id,
              name: u.email,
            }))}
            selectedItems={assigneeFilter.map((u) => ({
              id: u.id,
              name: u.email,
            }))}
            selectItem={(item, isSelected) => {
              setAssigneeFilter((prev) => {
                if (isSelected) {
                  return [
                    ...prev,
                    {
                      id: item.id,
                      email: item.name,
                      first_name: item.name,
                      last_name: item.name,
                    },
                  ];
                }
                return prev.filter((u) => u.id !== item.id);
              });
            }}
            clearSelectedItems={() => {
              setAssigneeFilter([]);
            }}
          />
        </div>
      </div>
      <div className="flex justify-center pt-4 pb-12">
        {questionsLoading && <LoadingView customText="Loading questions..." />}
        {questionsToDisplay.length > 0 && (
          <div className="flex space-y-4 w-[800px]">
            <Accordion type="single" collapsible className="w-full">
              {Object.keys(groupQuestionsBySection).map((sectionKey) => (
                <AccordionItem key={sectionKey} value={sectionKey}>
                  <AccordionTrigger>
                    <div className="flex space-x-4">
                      <span>
                        {sectionKey.length > 65
                          ? `${sectionKey.substring(0, 60)}...`
                          : sectionKey}
                      </span>
                      <Badge className="text-xs bg-gray-400">
                        {groupQuestionsBySection[sectionKey].length}
                      </Badge>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="space-y-4">
                      {groupQuestionsBySection[sectionKey].map((question) => (
                        <div className="cursor-pointer" key={question.id}>
                          <a
                            href={`/audit/${auditId}/${auditResourceId}/question/${question.id}/answer/${question.answer_id}`}
                          >
                            <QuestionCard question={question} />
                          </a>
                        </div>
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        )}
        {questionsToDisplay.length === 0 && !questionsLoading && (
          <div className="text-center text-gray-500">No questions found</div>
        )}
      </div>
    </Layout>
  );
};
