import { Button } from "../../shadcn/components/button";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Textarea } from "../../shadcn/components/textarea";
import { Separator } from "../../shadcn/components/separator";
import Markdown from "react-markdown";
import { ChatMessageType, Citation, ModelChat } from "../../types";
import { MarkdownCitationDisplay } from "../../components/MarkdownDisplay";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { extractTag } from "../../utils/format";
import { Badge } from "../../shadcn/components/badge";
import { cn } from "../../shadcn/lib/utils";
import { ReloadIcon } from "@radix-ui/react-icons";

const UserMessageContainer = (props: {
  message: ModelChat;
  onCitationClick: (citationId: string) => void;
}) => {
  let fmtMessage = props.message.content;
  let citationId: string | null = null;
  let messageType: ChatMessageType = "message";

  // extract message type
  const messageTypes = extractTag(props.message.content, "messageType");
  if (messageTypes.length > 0) {
    messageType = messageTypes[0] as ChatMessageType;
    // remove message type tag and it's content from the message
    fmtMessage = fmtMessage
      .replace(`<messageType>${messageTypes[0]}</messageType>`, "")
      .trim();

    // extract citation id
    const citationIds = extractTag(props.message.content, "citation");
    if (citationIds.length > 0) {
      citationId = citationIds[0];
      fmtMessage = fmtMessage
        .replace(`<citation>${citationIds[0]}</citation>`, "")
        .trim();
    }

    // remove relvant text tag
    fmtMessage = fmtMessage
      .replace(`<relevantText>`, "")
      .replace(`</relevantText>`, "")
      .trim();
  }
  return (
    <div className="flex justify-end w-full">
      <div
        className={cn(
          "bg-gray-300 rounded-3xl px-5 py-3 space-y-2 mt-5 mb-5 max-w-[90%]",
          citationId !== null &&
            "hover:cursor-pointer hover:bg-black hover:text-white"
        )}
        onClick={() => {
          if (citationId !== null) {
            props.onCitationClick(citationId);
          }
        }}
      >
        {messageType !== "message" && (
          <Badge>
            {(
              messageType.charAt(0).toUpperCase() + messageType.slice(1)
            ).replace("_", " ")}
          </Badge>
        )}
        <Markdown>{fmtMessage}</Markdown>
      </div>
    </div>
  );
};

const ChatMessageContainer = (props: {
  message: ModelChat;
  citationIds: string[];
  onCitationClick: (citationId: string) => void;
  lastDivRef: React.RefObject<HTMLDivElement> | null;
}) => {
  return (
    <div ref={props.lastDivRef}>
      {props.message.role === "user" ? (
        <UserMessageContainer
          message={props.message}
          onCitationClick={props.onCitationClick}
        />
      ) : (
        <div className="space-y-2 mt-5 mb-5">
          <MarkdownCitationDisplay
            text={props.message.content}
            clickableCitationIds={props.citationIds}
            onCitationClick={props.onCitationClick}
          />
        </div>
      )}
    </div>
  );
};

const ChatPanel = (props: {
  chatLoading: boolean;
  userMessage: (message: string) => void;
}) => {
  const [chatInput, setChatInput] = useState("");

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ): void => {
    if (
      event.key === "Enter" &&
      !event.shiftKey &&
      !event.nativeEvent.isComposing &&
      !props.chatLoading
    ) {
      event.preventDefault();
      if (chatInput !== "") {
        props.userMessage(chatInput);
      }
    }
  };

  useEffect(() => {
    if (!props.chatLoading) {
      setChatInput("");
    }
  }, [props.chatLoading]);

  return (
    <div className="relative py-2 pl-1">
      <Textarea
        tabIndex={0}
        onKeyDown={handleKeyDown}
        rows={3}
        value={chatInput}
        onChange={(e) => setChatInput(e.target.value)}
        placeholder={`Ask a question...`}
        spellCheck={false}
        className="pr-20"
        disabled={props.chatLoading}
      />
      <div className="absolute right-0 top-8 sm:right-4">
        <Button
          onClick={() => props.userMessage(chatInput)}
          size="sm"
          disabled={chatInput === "" || props.chatLoading}
        >
          Send{" "}
          {props.chatLoading && (
            <ReloadIcon className="w-4 h-4 ml-2 animate-spin" />
          )}
        </Button>
      </div>
    </div>
  );
};

export const ChatView = (props: {
  docId: string;
  setChatEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  citationClick: (citation: Citation) => void;
  chatMessages: {
    chat: ModelChat[];
    citations: Citation[];
  } | null;
  chatLoading: boolean;
  userMessage: (message: string) => void;
}) => {
  const { setCitations, setPageNumber } = useContext(DocViewerContext);
  const lastDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (lastDivRef && lastDivRef.current && props.chatLoading) {
      lastDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.chatMessages]);

  const onCitationClick = (citationId: string) => {
    const citation = props.chatMessages?.citations.find(
      (citation) => citation.id === citationId
    );
    if (citation) {
      setPageNumber(citation.page);
      setCitations([
        {
          match: citation.text,
          exactMatch: false,
          page: citation.page,
        },
      ]);
    }
  };

  return (
    <>
      <div className="h-[calc(100vh-208px)]">
        <div className="h-[calc(100vh-300px)] overflow-y-auto blur-content">
          <div className="space-y-2 flex justify-center">
            <div className="w-full">
              {props.chatMessages !== null &&
                props.chatMessages.chat.map((message, index) => (
                  <Fragment key={`chat-${index}`}>
                    <ChatMessageContainer
                      message={message}
                      citationIds={
                        props.chatMessages?.citations
                          .filter((citation) => citation.doc_id === props.docId)
                          .map((citation) => citation.id!) ?? []
                      }
                      onCitationClick={onCitationClick}
                      lastDivRef={
                        index === props.chatMessages!.chat.length - 1
                          ? lastDivRef
                          : null
                      }
                    />
                    {index < props.chatMessages!.chat.length - 1 && (
                      <Separator />
                    )}
                  </Fragment>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 bg-secondary w-full pr-4">
        <ChatPanel
          chatLoading={props.chatLoading}
          userMessage={props.userMessage}
        />
      </div>
    </>
  );
};
