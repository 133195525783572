import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  PdfCitation,
  CitationHighlight,
  HighlightBox,
  HighlightEnd,
} from "./types";
import {
  getCharIndexUnderMouse,
  isHighlightable,
  isCurrentBeforeAnchor,
  applyOffsetToHighlight,
  getPxOffsetOfIndex,
  getSpansSortedByheight,
  createMaxBox,
  printHighlights,
  getStartEndIndeciesOfRegex,
} from "./util";
import { usePdf } from "../PdfContext";
import { filterMapByKeyRange } from "../util";
import { CitationText } from "../../../types";

export const HIGHLIGHTED_CITATION_ID = "rangeCitation";
export type PdfHighlighterContextType = {
  rangeHighlightsRef: React.MutableRefObject<CitationHighlight | null> | null;
  onHighlightLayerRender: (page: number) => CitationHighlight[];
  onTextLayerRender: (page: number) => void;
  isHighlighting: boolean;
};
const PdfHighlighterContext = createContext<PdfHighlighterContextType>({
  rangeHighlightsRef: null,
  onHighlightLayerRender: (page: number) => [],
  onTextLayerRender: (page: number) => {},
  isHighlighting: false,
});

const emptyHighlightEnd: HighlightEnd = {
  element: null,
  offset: -1,
  page: -1,
  offsetPxStartLetter: -1,
  offsetPxEndLetter: -1,
};
interface PdfHighlighterProps {
  children: React.ReactNode;
  tooltipClassName?: string;
}
export const PdfHighlighterProvider: React.FC<PdfHighlighterProps> = ({
  children,
  tooltipClassName,
}) => {
  const pdfHighlighterContainerRef = useRef<HTMLDivElement>(null);
  const rangeHighlightsRef = useRef<CitationHighlight | null>(null);
  const [highlightAnchor, setHighlightAnchor] =
    useState<HighlightEnd>(emptyHighlightEnd);
  const [highlightCurrent, setHighlightCurrent] =
    useState<HighlightEnd>(emptyHighlightEnd);
  const [isHighlighting, setIsHighlighting] = useState(false);
  const [isHighlightingBackwards, setIsHighlightingBackwards] = useState(false);
  //Cached
  const elementCache = useRef<Map<Element, HighlightBox>>(new Map());
  const spansSortedByHeight = useRef<
    Map<number, { element: Element; rect: DOMRect }[]>
  >(new Map());

  const {
    scale,
    citations,
    updateCitation,
    currentPage,
    overscanCount,
    containerRef,
    pageRefs,
    textLayerRefs,
    loadedPageStartIndex,
    loadedPageEndIndex,
  } = usePdf();

  const onHighlightLayerRender = useCallback(
    (page: number) => {
      const citationHighlights: CitationHighlight[] = [];
      for (const citation of citations) {
        if (citation.page === page) {
          const highlights = createHighlightsFromCitation(citation);
          const maxBox = createMaxBox(highlights);
          citationHighlights.push({
            ...citation,
            highlightBoxes: highlights,
            boundingRect: maxBox,
          });
        }
      }
      return citationHighlights;
    },
    [citations]
  );

  useEffect(() => {
    filterMapByKeyRange(
      spansSortedByHeight.current,
      currentPage - overscanCount,
      currentPage + overscanCount
    );
  }, [currentPage]);

  useEffect(() => {
    spansSortedByHeight.current = new Map();
    elementCache.current = new Map();
  }, [scale]);

  const onTextLayerRerender = (page: number) => {
    const textLayer = textLayerRefs.current?.get(page);
    if (textLayer && textLayer.isConnected) {
      const sortedSpans = getSpansSortedByheight(textLayer);
      spansSortedByHeight.current.set(page, sortedSpans);
    }
  };

  /**
   * Create a text blob from all spans, match against searchText, ignoring all characters between [a-z][0-9]
   * @param searchText
   * @returns
   */
  const createHighlightsFromCitation = (
    citation: PdfCitation
  ): HighlightBox[] => {
    if (!citation || !citation.match) {
      return [];
    }
    const { match: searchText, page } = citation;
    let concatenatedText = "";
    const nodes: {
      node: Node;
      pageNumber: number;
      startOffset: number;
      endOffset: number;
    }[] = [];

    const textLayer = textLayerRefs.current?.get(page);
    if (!textLayer) {
      return [];
    }

    const walker = document.createTreeWalker(
      textLayer,
      NodeFilter.SHOW_TEXT,
      null
    );
    let currentNode;

    while ((currentNode = walker.nextNode())) {
      const text = currentNode.textContent || "";

      nodes.push({
        node: currentNode,
        pageNumber: page,
        startOffset: concatenatedText.length,
        endOffset: concatenatedText.length + text.length,
      });
      concatenatedText += text;
    }

    let [startIndex, endIndex] = getStartEndIndeciesOfRegex(
      searchText,
      concatenatedText
    );
    if (startIndex === -1) {
      return [];
    }
    let rangeStartNode: HTMLElement | null = null;
    let rangeEndNode: HTMLElement | null = null;
    let rangeStartOffset = 0;
    let rangeEndOffset = 0;
    const newHighlightedElements: HighlightBox[] = [];

    for (let i = 0; i < nodes.length; i++) {
      const { node, pageNumber, startOffset, endOffset } = nodes[i];
      const pageRef = pageRefs.current?.get(pageNumber);
      if (!pageRef) {
        continue;
      }
      const nodeParentSpan = node.parentElement;
      if (!nodeParentSpan) {
        continue;
      }
      if (
        !rangeStartNode &&
        startIndex >= startOffset &&
        startIndex < endOffset
      ) {
        //We found our rage.start
        rangeStartNode = nodeParentSpan;
        if (!rangeStartNode.isConnected) {
          //We are trying to highlight text on a text layer that is between rendering
          //Next time we get a rendered text layer we will try to highlight again
          return [];
        }
        rangeStartOffset = startIndex - startOffset;
        const { offsetPxStartLetter: startOffsetPx } = getPxOffsetOfIndex(
          rangeStartNode,
          rangeStartOffset
        );
        if (endIndex > startOffset && endIndex <= endOffset) {
          //start node === end node
          rangeEndOffset = endIndex - startOffset;
          const { offsetPxEndLetter: endOffsetPx } = getPxOffsetOfIndex(
            rangeStartNode,
            rangeEndOffset
          );
          const highlightBox = createHighlightBoxesFromElement(
            rangeStartNode,
            pageRef,
            startOffsetPx,
            endOffsetPx
          );
          newHighlightedElements.push(highlightBox);
          break;
        }
        newHighlightedElements.push(
          createHighlightBoxesFromElement(
            rangeStartNode,
            pageRef,
            startOffsetPx
          )
        );
        continue;
      } //End StartNode
      if (endIndex > startOffset && endIndex <= endOffset) {
        //We found our range end node
        rangeEndNode = nodeParentSpan;
        rangeEndOffset = endIndex - startOffset;
        const { offsetPxEndLetter: endOffsetPx } = getPxOffsetOfIndex(
          rangeEndNode,
          rangeEndOffset
        );
        newHighlightedElements.push(
          createHighlightBoxesFromElement(rangeEndNode, pageRef, 0, endOffsetPx)
        );
        continue;
      } //End endNode
      if (rangeStartNode && rangeEndNode) {
        break;
      }
      if (
        rangeStartNode &&
        startIndex <= startOffset &&
        endOffset <= endIndex
      ) {
        //We have a middle node
        newHighlightedElements.push(
          createHighlightBoxesFromElement(nodeParentSpan, pageRef)
        );
      }
    }
    return newHighlightedElements;
  };

  /**
   * Find the page in PageRefs that the current mouse position is in (on the y axis only)
   * @param event
   */
  const findPageInFocus = (event: MouseEvent): number => {
    const { clientY } = event;

    for (let i = loadedPageStartIndex; i < loadedPageEndIndex + 1; i++) {
      const page = pageRefs.current?.get(i);
      if (page) {
        const rect = page.getBoundingClientRect();
        // Check if the mouse Y position is between the top and bottom of the page
        if (clientY >= rect.top && clientY <= rect.bottom) {
          return i;
        }
      }
    }
    return -1; // Return -1 if no page is found in focus
  };
  /**
   * Given our array of spans stored in state, sorted by textual order by rect.top, rect.left,
   * find the span/offset closest to the mouse position that should be highlighted
   * Different logic is applied to grab the span/offset depending on if we are highlighting backwards
   * or if we are to the left/right of the closest span
   * @param event MouseEvent
   * @returns The element closest to the span
   */
  const findClosestSpanToMouse = (
    event: MouseEvent,
    pageNumber: number
  ): Element | false => {
    const { clientX, clientY } = event;
    const pageRef = pageRefs.current?.get(pageNumber);
    const sortedSpans = spansSortedByHeight.current.get(pageNumber);
    if (!sortedSpans || !pageRef) {
      return false;
    }
    const pageTopOffset = pageRef.getBoundingClientRect().top;
    const clientYOffsetOnPage = clientY - pageTopOffset;
    //Could make binary search, but the volume of spans is so low it shouldnt matter
    let i;
    for (i = 0; i < sortedSpans.length - 1; i++) {
      //If the next span starts below MouseY, we know we have the last eligiable span
      const nextSpan = sortedSpans[i + 1];
      if (nextSpan.rect.top > clientYOffsetOnPage) {
        break;
      }
    }
    const closestSpan = sortedSpans[i];
    let j = i;
    for (j = i; j > 0; j--) {
      //Go back through the spans that are on the same line
      if (
        Math.abs(sortedSpans[i].rect.top - sortedSpans[j - 1].rect.top) > 10
      ) {
        //The next element up is a new line
        break;
      }
    }
    const startSpanOnLine = sortedSpans[j];
    if (closestSpan.rect.bottom > clientYOffsetOnPage) {
      //We are on the x axis of a line
      if (clientX < startSpanOnLine.rect.left) {
        //We are to the left of the text, return accordingly
        if (isHighlightingBackwards) {
          return startSpanOnLine.element;
        }
        return sortedSpans[j - 1].element;
      }
    }
    if (isHighlightingBackwards) {
      //We are highlighting upwards, so the last highlighted element should be the one below the cursor
      return sortedSpans[i + 1]?.element || null;
    }
    return sortedSpans[i].element;
  };

  /**
   * Maintains a cache of elements -> boundingBox
   * Gets the cached dom rect and apply offset, if not cached update it
   * @param element
   * @param startOffset
   * @param endOffset
   * @returns
   */
  const createHighlightBoxesFromElement = (
    element: Element,
    pageRef: HTMLDivElement,
    startOffset: number = 0,
    endOffset: number | false = false
  ): HighlightBox => {
    if (elementCache.current.has(element)) {
      const cached = elementCache.current.get(element);
      if (cached) {
        return applyOffsetToHighlight(cached, startOffset, endOffset);
      }
    }
    const { left, top, width, height } = element.getBoundingClientRect();

    const { left: pageLeft, top: pageTop } =
      pageRef.getBoundingClientRect() || { top: 0, left: 0 };

    const elemHighlight = {
      left: left - pageLeft,
      top: top - pageTop,
      width: width,
      height: height,
    };
    // Cache the element's highlight box
    elementCache.current.set(element, elemHighlight);

    // Apply offset adjustments to the highlight if necessary
    return applyOffsetToHighlight(elemHighlight, startOffset, endOffset);
  };

  const getElementsInRangeForPage = (
    range: Range,
    pageElement: HTMLDivElement,
    currentHighlight: HighlightEnd
  ): HighlightBox[] => {
    const elements: HighlightBox[] = [];
    const treeWalker = document.createTreeWalker(
      pageElement,
      NodeFilter.SHOW_ELEMENT,
      {
        acceptNode: (node) => {
          let closestSpan = node;
          if (range.intersectsNode(closestSpan)) {
            return NodeFilter.FILTER_ACCEPT;
          }
          return NodeFilter.FILTER_REJECT;
        },
      }
    );

    while (treeWalker.nextNode()) {
      const node = treeWalker.currentNode;
      const roleText = (node as Element).getAttribute("role") || "";
      if (!roleText.includes("presentation")) {
        continue;
      }
      if ((node as Element) === highlightAnchor?.element) {
        if ((node as Element) === currentHighlight.element) {
          //Start and end Node are the same
          return [
            createHighlightBoxesFromElement(
              node as Element,
              pageElement,
              isHighlightingBackwards
                ? currentHighlight.offsetPxStartLetter
                : highlightAnchor.offsetPxStartLetter,
              isHighlightingBackwards
                ? highlightAnchor.offsetPxStartLetter
                : currentHighlight.offsetPxEndLetter
            ),
          ];
        }
        elements.push(
          createHighlightBoxesFromElement(
            node as Element,
            pageElement,
            isHighlightingBackwards ? 0 : highlightAnchor.offsetPxStartLetter,
            isHighlightingBackwards ? highlightAnchor.offsetPxStartLetter : 0
          )
        );
      } else if ((node as Element) === currentHighlight?.element) {
        elements.push(
          createHighlightBoxesFromElement(
            node as Element,
            pageElement,
            isHighlightingBackwards ? currentHighlight.offsetPxStartLetter : 0,
            isHighlightingBackwards ? 0 : currentHighlight.offsetPxEndLetter
          )
        );
      } else {
        elements.push(
          createHighlightBoxesFromElement(node as Element, pageElement)
        );
      }
    }
    return elements;
  };

  /**
   * Update the highlighted elements across multiple pages and update state.
   */
  const updateHighlightedElements = (
    range: Range,
    currentHighlight: HighlightEnd
  ) => {
    if (!pageRefs.current) return;
    const highlightPage = pageRefs.current.get(currentHighlight.page);
    if (highlightPage) {
      const highlightBoxesForPage = getElementsInRangeForPage(
        range,
        highlightPage,
        currentHighlight
      );
      rangeHighlightsRef.current = {
        id: "rangeCitaiton",
        exactMatch: true,
        match: range.toString(),
        page: currentHighlight.page,
        highlightBoxes: highlightBoxesForPage,
        boundingRect: createMaxBox(highlightBoxesForPage),
      };
    }
  };

  /**
   * Takes the HighlightAnchor, HighlightCurrent from state and creates a range with those elements as the start/end
   * @returns
   */
  const createRangeFromHighlight = (
    currentHighlightEnd: HighlightEnd | false = false
  ) => {
    if (!highlightAnchor.element || !highlightCurrent.element) {
      return null;
    }
    const current = currentHighlightEnd
      ? currentHighlightEnd
      : highlightCurrent;
    const range = document.createRange();
    try {
      if (isHighlightingBackwards) {
        range.setStart(current?.element?.firstChild as Node, current.offset);
        range.setEnd(
          highlightAnchor?.element?.firstChild as Node,
          highlightAnchor.offset
        );
      } else {
        range.setStart(
          highlightAnchor?.element?.firstChild as Node,
          highlightAnchor.offset
        );
        range.setEnd(current?.element?.firstChild as Node, current.offset + 1);
      }
    } catch (err) {
      console.error("ERR:", err);
      return null;
    }
    return range;
  };

  const handleMouseUp = () => {
    setIsHighlighting(false);
    const range = createRangeFromHighlight();
    const highlightPage = pageRefs.current?.get(highlightCurrent.page);
    if (range && highlightPage) {
      const highlightBoxes = getElementsInRangeForPage(
        range,
        highlightPage,
        highlightCurrent
      );
      const newRangeCitaion: CitationHighlight = {
        id: HIGHLIGHTED_CITATION_ID,
        match: range.toString(),
        exactMatch: true,
        page: highlightCurrent.page,
        tooltipClassName,
        highlightBoxes: highlightBoxes,
        boundingRect: createMaxBox(highlightBoxes),
      };
      updateCitation(newRangeCitaion);
    }
    rangeHighlightsRef.current = null;
    setHighlightAnchor({
      element: null,
      offset: -1,
      page: -1,
      offsetPxStartLetter: -1,
      offsetPxEndLetter: -1,
    });
    setHighlightCurrent({
      element: null,
      offset: -1,
      page: -1,
      offsetPxStartLetter: -1,
      offsetPxEndLetter: -1,
    });
  };

  /**
   * When the mouse is out of bounds on a valid element, find the nearest valid element to it using the cached list of spans sorted by
   * height
   * @param event
   */
  const findNearestFocus = (event: MouseEvent): HighlightEnd | false => {
    const page = findPageInFocus(event);
    const span = findClosestSpanToMouse(event, page);
    if (span) {
      let offsetIndex;
      if (isHighlightingBackwards) {
        offsetIndex = 0;
      } else {
        offsetIndex = span.textContent?.length;
      }
      const currentHighlight: HighlightEnd = {
        element: span,
        page: page,
        offset: offsetIndex ? offsetIndex - 1 : 0,
        offsetPxStartLetter: 0,
        offsetPxEndLetter: 0,
      };
      setHighlightCurrent(currentHighlight);
      return currentHighlight;
    }
    return false;
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isHighlighting || event.buttons === 0) {
      return;
    }
    event.preventDefault();
    let currentHighlight: HighlightEnd | false = false;
    const focusPage = findPageInFocus(event);
    const elementUnderCursor = document.elementFromPoint(
      event.clientX,
      event.clientY
    );
    if (elementUnderCursor && isHighlightable(elementUnderCursor)) {
      const { offsetNum, offsetPxStartLetter, offsetPxEndLetter } =
        getCharIndexUnderMouse(elementUnderCursor as HTMLElement, event);
      let hoveredContainer = elementUnderCursor as Node;
      const anchorElem = highlightAnchor?.element || null;
      if (
        anchorElem === null ||
        (anchorElem?.contains(hoveredContainer as HTMLElement) &&
          hoveredContainer.textContent !== anchorElem?.textContent)
      ) {
        setHighlightAnchor({
          element: hoveredContainer as HTMLElement,
          offset: offsetNum ?? 0,
          page: focusPage,
          offsetPxStartLetter: offsetPxStartLetter ?? 0,
          offsetPxEndLetter: offsetPxEndLetter ?? 0,
        });
      }
      currentHighlight = {
        element: hoveredContainer as HTMLElement,
        offset: offsetNum ?? 0,
        page: focusPage,
        offsetPxStartLetter: offsetPxStartLetter ?? 0,
        offsetPxEndLetter: offsetPxEndLetter ?? 0,
      };
      if (
        !(
          hoveredContainer === highlightCurrent.element &&
          offsetNum === highlightCurrent.offset
        )
      ) {
        setHighlightCurrent(currentHighlight as HighlightEnd);
        //Highlight didn't move so stop the fn
        //TODO: This ends the highlight one block early because the offset / state is set async
        // return;
      }
      setIsHighlightingBackwards(
        isCurrentBeforeAnchor(highlightAnchor, currentHighlight)
      );
    } else if (highlightAnchor.element) {
      try {
        currentHighlight = findNearestFocus(event);
      } catch (e) {
        console.error(e);
      }
    }
    const range = createRangeFromHighlight(currentHighlight);
    if (range) {
      updateHighlightedElements(range, currentHighlight || highlightCurrent);
    }
  };
  const handleMouseDown = () => {
    setIsHighlighting(true);
  };
  useEffect(() => {
    const pageDiv = containerRef.current;
    if (pageDiv) {
      pageDiv.addEventListener("mouseup", handleMouseUp);
      pageDiv.addEventListener("mousemove", handleMouseMove);
      pageDiv.addEventListener("mousedown", handleMouseDown);
    }
    return () => {
      if (pageDiv) {
        pageDiv.removeEventListener("mouseup", handleMouseUp);
        pageDiv.removeEventListener("mousemove", handleMouseMove);
        pageDiv.removeEventListener("mousedown", handleMouseDown);
      }
    };
  }, [
    containerRef,
    handleMouseUp,
    handleMouseMove,
    handleMouseDown,
    spansSortedByHeight,
  ]);

  return (
    <PdfHighlighterContext.Provider
      value={{
        rangeHighlightsRef,
        onHighlightLayerRender: onHighlightLayerRender,
        onTextLayerRender: onTextLayerRerender,
        isHighlighting,
      }}
    >
      <div
        ref={pdfHighlighterContainerRef}
        style={{
          userSelect: "none",
          position: "relative",
          display: "contents",
        }}
      >
        {children}
      </div>
    </PdfHighlighterContext.Provider>
  );
};

export const usePdfHighlighter = () => {
  return useContext(PdfHighlighterContext);
};
