import { useContext, useEffect, useRef, useState } from "react";
import { Layout } from "../../components/Layout";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import {
  AuditQuestion,
  AuditQuestionAnswer,
  Citation,
  Note,
  QuestionAnswerId,
  SearchDocName,
  TaskStatus,
} from "../../types";
import { Textarea } from "../../shadcn/components/textarea";
import { useNavigate, useParams } from "react-router-dom";
import {
  createNarrative,
  getAuditQuestionAnswer,
  getAuditRelevantDocs,
  updateAuditQuestionAnswer,
  updateQuestionAssignments,
} from "../../utils/apiCalls";
import { useAuthInfo } from "@propelauth/react";
import { toast } from "sonner";
import { DocViewerCitation } from "../../components/DocViewer";
import { Button } from "../../shadcn/components/button";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CopyIcon,
  Pencil1Icon,
  ReloadIcon,
} from "@radix-ui/react-icons";
import { TimeAgo } from "../../utils/format";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../shadcn/components/breadcrumb";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { Separator } from "../../shadcn/components/separator";
import { UserContext } from "../../contexts/UserContext";
import { MultiSelectControl } from "../../components/MultiSelectControl";
import { StandardStatusSelector } from "../../components/StatusSelector";
import { NoteView } from "../../components/Notes";
import { CitationView } from "../../components/Citation/Citations";
import { DocViewerContext } from "../../contexts/DocViewerContext";

const BreadcrumbNav = (props: { auditId: string; auditResourceId: string }) => {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href="/audit">Audits</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink href={`/audit/${props.auditId}`}>
            Audit Resources
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink
            href={`/audit/${props.auditId}/questionnaire/${props.auditResourceId}`}
          >
            Questions
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Answer Review</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};

const AuditCitationView = (props: {
  question: string;
  citations: Citation[];
  activeCitationId: string | null;
  setActiveCitationId: React.Dispatch<React.SetStateAction<string | null>>;
  setAnswer: React.Dispatch<React.SetStateAction<AuditQuestionAnswer | null>>;
  setDoc: React.Dispatch<React.SetStateAction<SearchDocName | null>>;
}) => {
  const authInfo = useAuthInfo();
  const { auditResourceId, questionId, answerId } = useParams();
  const { internalDocTypes } = useContext(UserContext);
  const [relevantDocs, setRelevantDocs] = useState<SearchDocName[]>([]);
  const urlPrefix = `audit/answer`;
  const urlSuffix = `${auditResourceId}/${questionId}/${answerId}`;

  const onSuccess = (citation: Citation, existingCitation: Citation | null) => {
    props.setAnswer((prev) => {
      if (prev) {
        let newCitations = [...prev.citations];
        if (existingCitation) {
          newCitations = newCitations.filter(
            (citation) => citation.id !== existingCitation!.id
          );
        }
        return {
          ...prev,
          citations: [...newCitations, citation],
        };
      }
      return prev;
    });
  };

  useEffect(() => {
    const fetchRelevantDocs = async () => {
      const response = await getAuditRelevantDocs(
        auditResourceId ?? "",
        questionId ?? "",
        answerId ?? "",
        authInfo.accessToken ?? null
      );
      if (response !== null) {
        setRelevantDocs(response);
      } else {
        toast.error("Unable to get relevant documents");
      }
    };
    fetchRelevantDocs();
  }, [auditResourceId, questionId, answerId]);

  const onGenerateCitations = (citations: Citation[]) => {
    props.setAnswer((prev) => {
      if (prev) {
        return {
          ...prev,
          citations: [
            ...prev.citations.filter(
              (existingCitation) =>
                !citations.some(
                  (newCitation) => newCitation.text === existingCitation.text
                )
            ),
            ...citations,
          ],
        };
      }
      return prev;
    });
  };

  return (
    <CitationView
      persistUrl={{
        prefix: urlPrefix,
        suffix: urlSuffix,
      }}
      generateUrl={{
        prefix: urlPrefix,
        suffix: urlSuffix,
      }}
      relevantDocs={relevantDocs}
      allowedDocTypeIds={internalDocTypes.map((docType) => docType.id)}
      question={props.question}
      citations={props.citations}
      activeCitationId={props.activeCitationId}
      setActiveCitationId={props.setActiveCitationId}
      onNewCitationSuccess={onSuccess}
      headerChildren={
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="secondary" size="sm">
              Question
            </Button>
          </TooltipTrigger>
          <TooltipContent side="right" className="w-[600px]">
            {props.question}
          </TooltipContent>
        </Tooltip>
      }
      onDeleteCitationSuccess={(citation: Citation) => {
        props.setAnswer((prev) => {
          if (prev) {
            return {
              ...prev,
              citations: prev.citations.filter((c) => c.id !== citation.id),
            };
          }
          return prev;
        });
      }}
      labelText="Citations"
      setDoc={(ac) => {
        if (ac === null) {
          props.setDoc(null);
        } else {
          props.setDoc({
            id: null,
            doc_id: ac.doc_id!,
            name: ac.doc_name!,
            doc_type_name: "",
            additional_metadata: {},
            result_type: "filename",
            citation: null,
          });
        }
      }}
      onClickGenerate={onGenerateCitations}
    />
  );
};

export const NarrativeView = (props: {
  answer: AuditQuestionAnswer;
  setAnswer: React.Dispatch<React.SetStateAction<AuditQuestionAnswer | null>>;
  updateAnswer: (narrative?: string, status?: TaskStatus) => Promise<void>;
}) => {
  const { auditResourceId, questionId, answerId } = useParams();
  const authInfo = useAuthInfo();
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const cancelRef = useRef<boolean>(false);

  const updateNarrativeTimeout = async (narrative: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(async () => {
      await props.updateAnswer(narrative, props.answer.status);
    }, 1000);
  };

  const updateNarrative = (narrative: string, skipSave: boolean = false) => {
    props.setAnswer((prev) => {
      if (prev) {
        return {
          ...prev,
          narrative,
        };
      }
      return prev;
    });

    if (!skipSave) {
      updateNarrativeTimeout(narrative);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const generateNarrative = async () => {
    cancelRef.current = false;
    setGenerateLoading(true);
    try {
      for await (const output of createNarrative(
        auditResourceId ?? "",
        questionId ?? "",
        answerId ?? "",
        authInfo.accessToken ?? null
      )) {
        if (cancelRef.current) {
          break;
        }
        updateNarrative(output.narrative, true);
      }
    } catch (error: any) {
      console.error("There was an error generating the narrative", error);
      toast.error("Unable to generate narrative, please try again");
    } finally {
      setGenerateLoading(false);
    }
  };

  const copyNarrativeAnswerToClipboard = () => {
    if (props.answer) {
      let text = props.answer.narrative;
      navigator.clipboard.writeText(text);
      toast.success("Copied!");
    }
  };

  useEffect(() => {
    cancelRef.current = true;
  }, [answerId]);

  return (
    <>
      <div className="flex justify-between">
        <div className="font-semibold text-lg">Narrative Answer</div>
        <div className="space-x-2 flex-items-center">
          <Button variant="outline" onClick={generateNarrative}>
            {generateLoading ? (
              <ReloadIcon className="w-4 h-4 mr-2 animate-spin text-gray-500" />
            ) : (
              <Pencil1Icon className="w-4 h-4 mr-2 text-gray-500" />
            )}
            <span className="text-sm text-gray-500">Draft</span>
          </Button>
          {props.answer.narrative && (
            <Button variant="outline" onClick={copyNarrativeAnswerToClipboard}>
              <CopyIcon className="w-4 h-4 mr-2 text-gray-500" />
              <span className="text-sm text-gray-500">Copy</span>
            </Button>
          )}
        </div>
      </div>
      <Textarea
        tabIndex={0}
        rows={10}
        value={props.answer.narrative ?? ""}
        onChange={(e) => {
          updateNarrative(e.target.value, false);
        }}
        placeholder={`Enter updated text...`}
        spellCheck={false}
      />
    </>
  );
};

const AssignmentSelection = (props: {
  auditResourceId: string;
  question: AuditQuestion;
  setQuestion: React.Dispatch<React.SetStateAction<AuditQuestion | null>>;
}) => {
  const authInfo = useAuthInfo();
  const { simpleUsers } = useContext(UserContext);
  const selectUser = (
    item: { id: string; name: string },
    isSelected: boolean
  ) => {
    updateQuestionAssignments(
      props.auditResourceId,
      props.question.id,
      item.id,
      isSelected,
      false,
      authInfo.accessToken ?? null
    ).then((res) => {
      if (!res) {
        toast.error("Failed to update assignments");
      }
    });
    props.setQuestion((prev) => {
      if (prev) {
        return {
          ...prev,
          assignments: isSelected
            ? [
                ...prev.assignments,
                {
                  id: item.id,
                  email: item.name,
                  first_name: item.name,
                  last_name: item.name,
                },
              ]
            : prev.assignments.filter((u) => u.id !== item.id),
        };
      }
      return prev;
    });
  };

  const clearSelectedUsers = () => {
    updateQuestionAssignments(
      props.auditResourceId,
      props.question.id,
      "",
      false,
      true,
      authInfo.accessToken ?? null
    ).then((res) => {
      if (!res) {
        toast.error("Failed to update assignments");
      }
    });
    props.setQuestion((prev) => {
      if (prev) {
        return { ...prev, assignments: [] };
      }
      return prev;
    });
  };

  return (
    <MultiSelectControl
      title="Assignees"
      selectedItems={props.question.assignments.map((u) => ({
        id: u.id,
        name: u.email,
      }))}
      items={simpleUsers.map((u) => ({
        id: u.id,
        name: u.email,
      }))}
      selectItem={(item, isSelected) => selectUser(item, isSelected)}
      clearSelectedItems={clearSelectedUsers}
    />
  );
};

const AnswerStatusSelector = (props: {
  status: string;
  setAnswer: React.Dispatch<React.SetStateAction<AuditQuestionAnswer | null>>;
  updateAnswer: (narrative?: string, status?: TaskStatus) => Promise<void>;
}) => {
  const handleStatusChange = async (value: string) => {
    props.setAnswer((prev) => {
      if (prev) {
        return { ...prev, status: value as TaskStatus };
      }
      return prev;
    });
    await props.updateAnswer(undefined, value as TaskStatus);
  };

  return (
    <StandardStatusSelector
      status={props.status}
      handleStatusChange={handleStatusChange}
    />
  );
};

const AnswerNav = (props: {
  previousAnswerId: QuestionAnswerId | null;
  nextAnswerId: QuestionAnswerId | null;
  auditId: string;
  auditResourceId: string;
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center space-x-4">
      <Tooltip>
        <TooltipTrigger>
          <Button
            size="sm"
            variant="outline"
            disabled={!props.previousAnswerId}
            onClick={() => {
              navigate(
                `/audit/${props.auditId}/${props.auditResourceId}/question/${
                  props.previousAnswerId!.question_id
                }/answer/${props.previousAnswerId!.answer_id}`
              );
            }}
          >
            <ChevronLeftIcon className="w-4 h-4 text-gray-500" />
            <span className="text-sm text-gray-500 ml-2">Previous</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>Previous Question</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger>
          <Button
            size="sm"
            variant="outline"
            disabled={!props.nextAnswerId}
            onClick={() => {
              navigate(
                `/audit/${props.auditId}/${props.auditResourceId}/question/${
                  props.nextAnswerId!.question_id
                }/answer/${props.nextAnswerId!.answer_id}`
              );
            }}
          >
            <span className="text-sm text-gray-500 mr-2">Next</span>
            <ChevronRightIcon className="w-4 h-4 text-gray-500" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Next Question</TooltipContent>
      </Tooltip>
    </div>
  );
};

export const AuditAnswerReviewView = () => {
  const { auditId, auditResourceId, questionId, answerId } = useParams();
  const authInfo = useAuthInfo();
  const [question, setQuestion] = useState<AuditQuestion | null>(null);
  const [answer, setAnswer] = useState<AuditQuestionAnswer | null>(null);
  const [activeCitationId, setActiveCitationId] = useState<string | null>(null);
  const [doc, setDoc] = useState<SearchDocName | null>(null);
  const [previousAnswerId, setPreviousAnswerId] =
    useState<QuestionAnswerId | null>(null);
  const [nextAnswerId, setNextAnswerId] = useState<QuestionAnswerId | null>(
    null
  );
  const noteUrlPrefix = `audit/answer`;
  const noteUrlSuffix = `${auditResourceId}/${questionId}/${answerId}`;

  const updateAnswer = async (narrative?: string, status?: TaskStatus) => {
    if (answer && (narrative || status)) {
      const updatedAnswer = {
        ...answer,
        narrative: narrative ?? answer.narrative,
        status: status ?? answer.status,
      };
      const response = await updateAuditQuestionAnswer(
        auditResourceId ?? "",
        questionId ?? "",
        updatedAnswer,
        authInfo.accessToken ?? null
      );
      if (response !== null) {
        if (narrative) {
          setAnswer((prev) => {
            if (prev) {
              return {
                ...prev,
                narrative_updated_at: new Date().toISOString().slice(0, -1),
                narrative_updated_by: {
                  id: authInfo.user?.userId ?? "unknown",
                  email: authInfo.user?.email ?? "unknown",
                  first_name: authInfo.user?.firstName ?? "unknown",
                  last_name: authInfo.user?.lastName ?? "unknown",
                },
              };
            }
            return prev;
          });
        }
        if (status) {
          setAnswer((prev) => {
            if (prev) {
              return {
                ...prev,
                status_updated_at: new Date().toISOString().slice(0, -1),
                status_updated_by: {
                  id: authInfo.user?.userId ?? "unknown",
                  email: authInfo.user?.email ?? "unknown",
                  first_name: authInfo.user?.firstName ?? "unknown",
                  last_name: authInfo.user?.lastName ?? "unknown",
                },
              };
            }
            return prev;
          });
        }
      } else {
        toast.error("Failed to save answer");
      }
    }
  };

  useEffect(() => {
    if (auditResourceId && questionId && answerId) {
      getAuditQuestionAnswer(
        auditResourceId,
        questionId,
        answerId,
        authInfo.accessToken ?? null
      ).then((res) => {
        if (res !== null) {
          setQuestion(res.question);
          setAnswer(res.answer);
          if (res.answer.citations.length > 0) {
            setActiveCitationId(res.answer.citations[0].id);
          }
          setPreviousAnswerId(res.previous_question_answer_id);
          setNextAnswerId(res.next_question_answer_id);
        } else {
          toast.error("Failed to load existing answer, please refresh");
        }
      });
    }
  }, [auditResourceId, questionId, answerId]);

  const addNote = (note: Note) => {
    setAnswer((prev) => {
      if (prev) {
        return { ...prev, notes: [...prev.notes, note] };
      }
      return prev;
    });
  };

  const onNoteReact = (noteId: string, reaction: boolean) => {
    setAnswer((prev) => {
      if (prev) {
        const existingNoteReactions = prev.notes.find(
          (n) => n.id === noteId
        )?.reactions;
        if (existingNoteReactions) {
          const newNoteReactions = reaction
            ? [
                ...existingNoteReactions,
                {
                  user: {
                    id: authInfo.user?.userId ?? "unknown",
                    email: authInfo.user?.email ?? "unknown",
                    first_name: authInfo.user?.firstName ?? "unknown",
                    last_name: authInfo.user?.lastName ?? "unknown",
                  },
                  reaction: "U+1F44D",
                },
              ]
            : existingNoteReactions.filter(
                (r) => r.user.id !== authInfo.user?.userId
              );
          return {
            ...prev,
            notes: prev.notes.map((n) =>
              n.id === noteId ? { ...n, reactions: newNoteReactions } : n
            ),
          };
        }
        return prev;
      }
      return prev;
    });
  };

  const deleteNote = (noteId: string) => {
    setAnswer((prev) => {
      if (prev) {
        return {
          ...prev,
          notes: prev.notes.filter((n) => n.id !== noteId),
        };
      }
      return prev;
    });
  };

  return (
    <Layout pageName="Audits">
      <BreadcrumbNav
        auditId={auditId ?? ""}
        auditResourceId={auditResourceId ?? ""}
      />
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel
          defaultSize={60}
          minSize={40}
          maxSize={60}
          id="resource-panel"
          order={2}
        >
          <div className="space-y-4 pb-10 pl-1 pr-5 h-[calc(100vh-165px)] overflow-y-auto">
            <Separator />
            {question && (
              <div className="space-y-2 pb-4">
                <AnswerNav
                  previousAnswerId={previousAnswerId}
                  nextAnswerId={nextAnswerId}
                  auditId={auditId ?? ""}
                  auditResourceId={auditResourceId ?? ""}
                />
                <div className="font-semibold underline text-lg">
                  {question.section_index}.&nbsp;&nbsp;{question.section_title}
                </div>
                <div className="text-sm">
                  {question.question_index}.&nbsp;&nbsp;{question.question}
                </div>
                <div className="flex items-center justify-between pb-3 pr-2">
                  <AssignmentSelection
                    auditResourceId={auditResourceId ?? ""}
                    question={question}
                    setQuestion={setQuestion}
                  />

                  <AnswerStatusSelector
                    status={answer?.status ?? "todo"}
                    setAnswer={setAnswer}
                    updateAnswer={updateAnswer}
                  />
                </div>
              </div>
            )}
            <Separator />
            {answer && (
              <>
                <NarrativeView
                  answer={answer}
                  setAnswer={setAnswer}
                  updateAnswer={updateAnswer}
                />
                {answer.narrative_updated_at && (
                  <div className="text-xs text-gray-500 text-center">
                    Last updated{" "}
                    <TimeAgo timestamp={answer.narrative_updated_at} /> by{" "}
                    {`${answer.narrative_updated_by.first_name}${answer.narrative_updated_by.last_name ? ` ${answer.narrative_updated_by.last_name[0]}.` : ""}`}
                  </div>
                )}
                <Separator />
                <AuditCitationView
                  question={question?.question ?? ""}
                  citations={answer.citations}
                  activeCitationId={activeCitationId}
                  setActiveCitationId={setActiveCitationId}
                  setAnswer={setAnswer}
                  setDoc={setDoc}
                />
                <Separator />
                <NoteView
                  notes={answer.notes}
                  addNote={addNote}
                  onReact={onNoteReact}
                  deleteNote={deleteNote}
                  urlPrefix={noteUrlPrefix}
                  urlSuffix={noteUrlSuffix}
                />
              </>
            )}
          </div>
        </ResizablePanel>
        {activeCitationId && <ResizableHandle withHandle className="mx-4" />}
        <ResizablePanel
          defaultSize={40}
          minSize={40}
          maxSize={60}
          id="doc-view-panel"
          order={3}
        >
          {doc && (
            <DocViewerCitation
              docId={doc.doc_id}
              className="h-[calc(100vh-260px)]"
              hideAtlasWidget={true}
            />
          )}
          {(!answer || answer.citations.length === 0) && (
            <div className="flex justify-center items-center h-full">
              <div className="text-center text-gray-500">No citations</div>
            </div>
          )}
        </ResizablePanel>
      </ResizablePanelGroup>
    </Layout>
  );
};
