import { useContext, useEffect, useRef, useState } from "react";
import { Input } from "../../shadcn/components/input";
import { MultiSelectControl } from "../MultiSelectControl";
import {
  ActionItem,
  Department,
  Note,
  Requirement,
  SearchDocName,
  TaskStatus,
  statusToLabelMap,
} from "../../types";
import { UserContext } from "../../contexts/UserContext";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../shadcn/components/accordion";
import { Badge } from "../../shadcn/components/badge";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import { StandardStatusSelector, StatusBadge } from "../StatusSelector";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import {
  ActionItemDialog,
  ActionItemNote,
  AssigneeSelection,
  ConfirmActionItemDeletion,
} from "./ActionItemDialog";
import { DocViewerCitation } from "../DocViewer";
import { NoteView } from "../Notes";
import { useAuthInfo } from "@propelauth/react";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { Button } from "../../shadcn/components/button";
import { PlusIcon } from "@radix-ui/react-icons";
import { saveActionItem, saveAssignees } from "../../utils/apiCalls";
import { toast } from "sonner";
import { TimeAgo } from "../../utils/format";
import { useSearchParams, SetURLSearchParams } from "react-router-dom";
import { Separator } from "../../shadcn/components/separator";
import { ActionItemFullDocumentsView } from "./ActionItemDocumentView";

const ActionItemViewHeader = (props: {
  docId: string;
  requirements: Requirement[];
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  actionItem: ActionItem | null;
  setActionItem: React.Dispatch<React.SetStateAction<ActionItem | null>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  statusFilter: TaskStatus[];
  setStatusFilter: React.Dispatch<React.SetStateAction<TaskStatus[]>>;
  departmentFilter: Department[];
  setDepartmentFilter: React.Dispatch<React.SetStateAction<Department[]>>;
  allowedDocTypeIds: string[];
}) => {
  const authInfo = useAuthInfo();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { departments } = useContext(UserContext);
  return (
    <div className="flex items-center space-x-2">
      <ActionItemDialog
        docId={props.docId}
        requirements={props.requirements}
        existingActionItem={props.actionItem}
        open={dialogOpen}
        setOpen={setDialogOpen}
        setRequirements={props.setRequirements}
        allowedDocTypeIds={props.allowedDocTypeIds}
        hideAtlasWidget={true}
      />
      <Button
        variant="default"
        onClick={() => {
          props.setActionItem({
            id: "",
            text: "",
            citations: [],
            assignees: [],
            status: "todo",
            text_updated_at: "",
            text_updated_by: {
              id: authInfo.user?.userId ?? "unknown",
              email: authInfo.user?.email ?? "unknown",
              first_name: authInfo.user?.firstName ?? "unknown",
              last_name: authInfo.user?.lastName ?? "unknown",
            },
            status_updated_at: "",
            status_updated_by: {
              id: authInfo.user?.userId ?? "unknown",
              email: authInfo.user?.email ?? "unknown",
              first_name: authInfo.user?.firstName ?? "unknown",
              last_name: authInfo.user?.lastName ?? "unknown",
            },
            created_at: new Date().toISOString().slice(0, -1),
            notes: [],
            requirement_id: "",
            updated_at: new Date().toISOString().slice(0, -1),
            reference_doc_types: null,
            reference_documents: [],
          });
          setDialogOpen(true);
        }}
      >
        <PlusIcon className="w-4 h-4 mr-2" />
        Action
      </Button>
      <Input
        className="flex-grow"
        placeholder="Search action items..."
        value={props.search}
        onChange={(e) => props.setSearch(e.target.value)}
      />
      <MultiSelectControl
        title="Status"
        items={["todo", "review", "done"].map((status) => ({
          id: status,
          name: statusToLabelMap[status as TaskStatus],
        }))}
        selectedItems={props.statusFilter.map((status) => ({
          id: status,
          name: statusToLabelMap[status as TaskStatus],
        }))}
        clearSelectedItems={() => {
          props.setStatusFilter([]);
        }}
        selectItem={(item, isSelected) =>
          props.setStatusFilter((prev) => {
            if (isSelected) {
              return [...prev, item.id as TaskStatus];
            }
            return prev.filter((s) => s !== item.id);
          })
        }
      />
      <MultiSelectControl
        title="Department"
        items={departments.map((u) => ({
          id: u.id,
          name: u.name,
        }))}
        selectedItems={props.departmentFilter.map((u) => ({
          id: u.id,
          name: u.name,
        }))}
        selectItem={(item, isSelected) => {
          props.setDepartmentFilter((prev) => {
            if (isSelected) {
              return [
                ...prev,
                {
                  id: item.id,
                  name: item.name,
                },
              ];
            }
            return prev.filter((u) => u.id !== item.id);
          });
        }}
        clearSelectedItems={() => {
          props.setDepartmentFilter([]);
        }}
      />
    </div>
  );
};

const ActionViewBody = (props: {
  actionItems: ActionItem[];
  actionItem: ActionItem | null;
  docId: string;
  setDoc: React.Dispatch<React.SetStateAction<SearchDocName | null>>;
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  setActionItem: React.Dispatch<React.SetStateAction<ActionItem | null>>;
  requirement: Requirement | null;
  search: string;
  statusFilter: TaskStatus[];
  departmentFilter: Department[];
  setSearchParams: SetURLSearchParams;
  allowedDocTypeIds: string[];
  requirementItemRoutePrefix: string;
}) => {
  const authInfo = useAuthInfo();
  const [activeCitationId, setActiveCitationId] = useState<string | null>(null);
  const { setPageNumber, setCitations } = useContext(DocViewerContext);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const actionItemsToDisplay = props.actionItems
    .filter(
      (actionItem) =>
        actionItem.text.toLowerCase().includes(props.search.toLowerCase()) ||
        props.search === ""
    )
    .filter((actionItem) => {
      return (
        props.statusFilter.length === 0 ||
        props.statusFilter.includes(actionItem.status)
      );
    })
    .filter((actionItem) => {
      return (
        props.departmentFilter.some((department) =>
          actionItem.assignees.some((a) => a.id === department.id)
        ) || props.departmentFilter.length === 0
      );
    });

  const addNote = async (note: Note) => {
    props.setActionItem((prev) => {
      if (prev) {
        return { ...prev, notes: [...prev.notes, note] };
      }
      return prev;
    });
  };

  const onNoteReact = (noteId: string, reaction: boolean) => {
    props.setActionItem((prev) => {
      if (prev) {
        const existingNoteReactions = prev.notes.find(
          (n) => n.id === noteId
        )?.reactions;
        if (existingNoteReactions) {
          const newNoteReactions = reaction
            ? [
                ...existingNoteReactions,
                {
                  user: {
                    id: authInfo.user?.userId ?? "unknown",
                    email: authInfo.user?.email ?? "unknown",
                    first_name: authInfo.user?.firstName ?? "unknown",
                    last_name: authInfo.user?.lastName ?? "unknown",
                  },
                  reaction: "U+1F44D",
                },
              ]
            : existingNoteReactions.filter(
                (r) => r.user.id !== authInfo.user?.userId
              );
          return {
            ...prev,
            notes: prev.notes.map((n) =>
              n.id === noteId ? { ...n, reactions: newNoteReactions } : n
            ),
          };
        }
        return prev;
      }
      return prev;
    });
  };

  const deleteNote = (noteId: string) => {
    props.setActionItem((prev) => {
      if (prev) {
        return {
          ...prev,
          notes: prev.notes.filter((n) => n.id !== noteId),
        };
      }
      return prev;
    });
  };

  useEffect(() => {
    if (activeCitationId && props.actionItem) {
      const activeCitation = props.actionItem.citations.find(
        (citation) => citation.id === activeCitationId
      );
      if (activeCitation) {
        setSelectedRequirement(false);
        props.setDoc({
          id: "",
          doc_id: activeCitation.doc_id ?? "",
          name: activeCitation.doc_name ?? "",
          doc_type_name: "",
          additional_metadata: {},
          result_type: "filename",
          citation: activeCitation,
        });
        setPageNumber(activeCitation.page ?? 1);
        setCitations([
          {
            id: activeCitation.id ?? "",
            match: activeCitation.text ?? "",
            exactMatch: false,
            page: activeCitation.page ?? 1,
            isSelected: true,
          },
        ]);
      }
    }
  }, [activeCitationId, props.actionItem]);

  const [selectedRequirement, setSelectedRequirement] =
    useState<boolean>(false);

  useEffect(() => {
    if (props.actionItem) {
      props.setRequirements((prev) => {
        return prev.map((r) => {
          if (r.id === props.actionItem!.requirement_id) {
            return {
              ...r,
              action_items: r.action_items.map((a) =>
                a.id === props.actionItem!.id ? props.actionItem! : a
              ),
            };
          }
          return r;
        });
      });
    }
  }, [props.actionItem]);

  useEffect(() => {
    if (props.requirement && selectedRequirement) {
      props.setDoc({
        id: "",
        doc_id: props.requirement.citations[0].doc_id ?? "",
        name: props.requirement.citations[0].doc_name ?? "",
        doc_type_name: "",
        additional_metadata: {},
        result_type: "filename",
        citation: null,
      });
      setPageNumber(props.requirement.citations[0].page ?? 1);
      setCitations([
        {
          id: props.requirement.citations[0].id ?? "",
          match: props.requirement.citations[0].text ?? "",
          exactMatch: false,
          page: props.requirement.citations[0].page ?? 1,
        },
      ]);
    }
  }, [props.requirement, selectedRequirement]);

  const updateTextTimeout = async (text: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      updateActiveItem(text, undefined);
    }, 1000);
  };

  // listen for changes to status and text
  const updateActiveItem = async (text?: string, status?: TaskStatus) => {
    const actionItem = props.actionItem;
    if (actionItem) {
      if (text) {
        actionItem.text = text;
      }
      if (status) {
        actionItem.status = status;
      }
      const response = await saveActionItem(
        props.docId,
        actionItem.requirement_id,
        actionItem,
        authInfo.accessToken ?? ""
      );
      if (response === true) {
        if (props.actionItem?.status) {
          props.setActionItem((prev) => {
            if (prev) {
              return {
                ...prev,
                status_updated_by: {
                  id: authInfo.user?.userId ?? "unknown",
                  email: authInfo.user?.email ?? "unknown",
                  first_name: authInfo.user?.firstName ?? "unknown",
                  last_name: authInfo.user?.lastName ?? "unknown",
                },
                status_updated_at: new Date().toISOString().slice(0, -1),
              };
            }
            return prev;
          });
        }
        if (props.actionItem?.text) {
          props.setActionItem((prev) => {
            if (prev) {
              return {
                ...prev,
                text_updated_by: {
                  id: authInfo.user?.userId ?? "unknown",
                  email: authInfo.user?.email ?? "unknown",
                  first_name: authInfo.user?.firstName ?? "unknown",
                  last_name: authInfo.user?.lastName ?? "unknown",
                },
                text_updated_at: new Date().toISOString().slice(0, -1),
              };
            }
            return prev;
          });
        }
      } else {
        toast.error("Error saving action item");
      }
    }
  };

  useEffect(() => {
    if (props.actionItem?.assignees && props.actionItem.id) {
      saveAssignees(
        `gap-analysis/action-item/assignees/${props.docId}/${props.actionItem.requirement_id}/${props.actionItem.id}`,
        props.actionItem.assignees,
        authInfo.accessToken ?? ""
      ).then((response) => {
        if (response === false) {
          toast.error("Error updating assignees");
        }
      });
    }
  }, [props.actionItem?.assignees]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  return (
    <div className="overflow-y-auto h-[calc(100vh-250px)] mt-4 pb-12 pr-6">
      {actionItemsToDisplay.length > 0 && (
        <Accordion
          type="single"
          collapsible
          className="w-full"
          value={props.actionItem?.id ?? ""}
          onValueChange={(actionItemId) => {
            props.setActionItem(
              actionItemsToDisplay.find((a) => a.id === actionItemId) ?? null
            );
            setActiveCitationId(null);
            setSelectedRequirement(true);
            props.setSearchParams((prev) => {
              prev.set("actionItemId", actionItemId);
              return prev;
            });
          }}
        >
          {actionItemsToDisplay
            .sort((a, b) => {
              return Date.parse(b.updated_at) - Date.parse(a.updated_at);
            })
            .map((actionItem) => (
              <AccordionItem
                key={actionItem.id}
                value={actionItem.id}
                id={`i${actionItem.id}`}
              >
                <AccordionTrigger>
                  <div className="flex flex-grow items-center justify-between">
                    <Tooltip>
                      <TooltipTrigger>
                        <div className="text-left overflow-hidden whitespace-nowrap w-[300px] text-ellipsis">
                          {actionItem.text}
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>{actionItem.text}</TooltipContent>
                    </Tooltip>
                    <div className="flex items-center">
                      {actionItem.assignees.length > 0 && (
                        <Tooltip>
                          <TooltipTrigger>
                            <Badge className="bg-gray-600 transform scale-75">
                              {actionItem.assignees.length} Departments
                            </Badge>
                          </TooltipTrigger>
                          <TooltipContent>
                            <div className="space-y-1">
                              {actionItem.assignees.map((assignee) => (
                                <div className="text-left" key={assignee.id}>
                                  {assignee.name}
                                </div>
                              ))}
                            </div>
                          </TooltipContent>
                        </Tooltip>
                      )}
                      <div className="flex items-center transform scale-75">
                        <StatusBadge status={actionItem.status} />
                      </div>
                      <div className="text-gray-500 transform scale-75">
                        <TimeAgo timestamp={actionItem.updated_at} />
                      </div>
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="bg-white p-4 space-y-4">
                    {actionItem && (
                      <div className="flex items-center justify-between">
                        <StandardStatusSelector
                          status={actionItem.status}
                          handleStatusChange={async (value) => {
                            props.setActionItem((prev) => {
                              if (prev) {
                                return { ...prev, status: value as TaskStatus };
                              }
                              return prev;
                            });
                            await updateActiveItem(
                              undefined,
                              value as TaskStatus
                            );
                          }}
                        />
                        <div className="flex items-center space-x-2">
                          <Tooltip>
                            <TooltipTrigger>
                              <div className="text-sm text-blue-500 cursor-pointer hover:underline hover:text-blue-700 truncate">
                                <a
                                  href={`${props.requirementItemRoutePrefix}${props.requirement?.id}`}
                                >
                                  Requirement
                                </a>
                              </div>
                            </TooltipTrigger>
                            <TooltipContent>
                              <div className="text-left max-w-[300px] text-ellipsis">
                                {props.requirement?.text}
                              </div>
                            </TooltipContent>
                          </Tooltip>
                          <ConfirmActionItemDeletion
                            docId={props.docId}
                            actionItem={actionItem}
                            setRequirements={props.setRequirements}
                          >
                            <Button size="sm" variant="destructive">
                              Delete Action Item
                            </Button>
                          </ConfirmActionItemDeletion>
                        </div>
                      </div>
                    )}
                    <ActionItemNote
                      actionItem={actionItem}
                      setActionItem={props.setActionItem}
                      updateActiveItem={updateTextTimeout}
                    />
                    <Separator />
                    <AssigneeSelection
                      activeAssignees={actionItem?.assignees ?? []}
                      setActionItem={props.setActionItem}
                    />
                    {props.requirement && (
                      <div className="space-y-6">
                        <Separator />
                        <ActionItemFullDocumentsView
                          docId={props.docId}
                          requirement={props.requirement}
                          actionItemId={actionItem?.id}
                          setActionItem={props.setActionItem}
                          activeCitationId={activeCitationId}
                          setActiveCitationId={setActiveCitationId}
                          setDoc={props.setDoc}
                          hideAtlasWidget={true}
                          actionItem={actionItem}
                        />
                      </div>
                    )}
                    <Separator />
                    <NoteView
                      notes={actionItem?.notes ?? []}
                      addNote={addNote}
                      onReact={onNoteReact}
                      deleteNote={deleteNote}
                      urlPrefix="gap-analysis/action-item"
                      urlSuffix={`${props.docId}/${actionItem.requirement_id}/${actionItem?.id}`}
                    />
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
        </Accordion>
      )}
      {actionItemsToDisplay.length === 0 && (
        <div className="text-center text-gray-500">No action items found</div>
      )}
    </div>
  );
};

export const ActionItemView = (props: {
  docId: string;
  requirements: Requirement[];
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  allowedDocTypeIds: string[];
  requirementItemRoutePrefix: string;
}) => {
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState<TaskStatus[]>([]);
  const [departmentFilter, setDepartmentFilter] = useState<Department[]>([]);
  const [doc, setDoc] = useState<SearchDocName | null>(null);
  const [actionItem, setActionItem] = useState<ActionItem | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const requirement = props.requirements.find(
    (r) => r.id === actionItem?.requirement_id
  );

  const actionItems = props.requirements.flatMap(
    (requirement) => requirement.action_items
  );

  useEffect(() => {
    if (!actionItem?.id) {
      const actionItemId = searchParams.get("actionItemId");
      const actionToSelect = actionItems.find((a) => a.id === actionItemId);
      if (actionToSelect) {
        setActionItem(actionToSelect);
        const section = document.querySelector(`#i${actionItemId}`);
        if (section) {
          section.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (actionItem) {
      setSearchParams(
        (prev) => {
          prev.set("actionItemId", actionItem.id);
          prev.delete("requirementId");
          return prev;
        },
        {
          replace: true,
        }
      );
    }
  }, [actionItem]);

  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel
        defaultSize={50}
        minSize={50}
        maxSize={60}
        id="resource-panel"
        order={2}
      >
        <ActionItemViewHeader
          docId={props.docId}
          requirements={props.requirements}
          setRequirements={props.setRequirements}
          actionItem={actionItem}
          setActionItem={setActionItem}
          search={search}
          setSearch={setSearch}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          departmentFilter={departmentFilter}
          setDepartmentFilter={setDepartmentFilter}
          allowedDocTypeIds={props.allowedDocTypeIds}
        />
        <ActionViewBody
          actionItems={actionItems}
          actionItem={actionItem}
          docId={props.docId}
          setDoc={setDoc}
          allowedDocTypeIds={props.allowedDocTypeIds}
          setRequirements={props.setRequirements}
          setActionItem={setActionItem}
          requirement={requirement ?? null}
          search={search}
          statusFilter={statusFilter}
          departmentFilter={departmentFilter}
          setSearchParams={setSearchParams}
          requirementItemRoutePrefix={props.requirementItemRoutePrefix}
        />
      </ResizablePanel>
      <ResizableHandle withHandle className="mx-4" />
      <ResizablePanel
        defaultSize={50}
        minSize={40}
        maxSize={50}
        id="doc-view-panel"
        order={3}
      >
        {doc && (
          <DocViewerCitation
            docId={doc.doc_id}
            className="h-[calc(100vh-290px)]"
            hideAtlasWidget={true}
          />
        )}
        {(!actionItem || actionItem.citations.length === 0) && (
          <div className="flex justify-center items-center h-full">
            <div className="text-center text-gray-500">No citations</div>
          </div>
        )}
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};
