import { createContext, useState } from "react";
import { Citation, Requirement, SimpleUser } from "../types";
import { useAuthInfo } from "@propelauth/react";
import { generateRequirement, getRequirements } from "../utils/apiCalls";
import { toast } from "sonner";
import { citationSortOrder } from "../utils/format";
import { HIGHLIGHTED_CITATION_ID } from "../components/PdfViewer";

interface RequirementContextProps {
  requirements: Requirement[];
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  loadRequirements: (docId: string) => Promise<void>;
  addRequirement: (docId: string, pageNumber: number) => Promise<void>;
  generateRequirementLoading: boolean;
  requirementsLoading: boolean;
  viewType: "table" | "list";
  setViewType: React.Dispatch<React.SetStateAction<"table" | "list">>;
  activeRequirement: Requirement | undefined;
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  editingIndividualRequirementCitation: boolean;
  setEditingIndividualRequirementCitation: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  activeRequirementId: string;
  setActiveRequirementId: React.Dispatch<React.SetStateAction<string>>;
  individualRequirementActiveCitationIndex: number;
  setIndividualRequirementActiveCitationIndex: React.Dispatch<
    React.SetStateAction<number>
  >;
}

export const RequirementContext = createContext<RequirementContextProps>({
  requirements: [],
  setRequirements: () => {},
  loadRequirements: async () => {},
  addRequirement: async () => {},
  generateRequirementLoading: false,
  requirementsLoading: false,
  viewType: "table",
  setViewType: () => {},
  activeRequirement: undefined,
  activeRequirementId: "",
  setActiveRequirementId: () => {},
  editing: false,
  setEditing: () => {},
  editingIndividualRequirementCitation: false,
  setEditingIndividualRequirementCitation: () => {},
  individualRequirementActiveCitationIndex: 0,
  setIndividualRequirementActiveCitationIndex: () => {},
});

export const RequirementProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const authInfo = useAuthInfo();
  const [activeRequirementId, setActiveRequirementId] = useState<string>("");
  const [editing, setEditing] = useState<boolean>(false);
  const [
    editingIndividualRequirementCitation,
    setEditingIndividualRequirementCitation,
  ] = useState<boolean>(false);
  const [requirementsLoading, setRequirementsLoading] =
    useState<boolean>(false);
  const [requirements, setRequirements] = useState<Requirement[]>([]);
  const [viewType, setViewType] = useState<"table" | "list">("table");
  const [generateRequirementLoading, setGenerateRequirementLoading] =
    useState<boolean>(false);

  const [
    individualRequirementActiveCitationIndex,
    setIndividualRequirementActiveCitationIndex,
  ] = useState(0);
  const activeRequirement = requirements.find(
    (requirement) => requirement.id === activeRequirementId
  );

  const loadRequirements = async (docId: string) => {
    setRequirementsLoading(true);
    getRequirements(docId, authInfo.accessToken ?? null).then((response) => {
      if (response !== null) {
        const newRequirements = response.requirements.sort(citationSortOrder);
        setRequirements(newRequirements);
      } else {
        toast.error("Failed to load requirements");
      }
      setRequirementsLoading(false);
    });
  };

  const addRequirement = async (docId: string, pageNumber: number) => {
    let response = null;

    // if there are existing requirements, generate a new one
    if (requirements.length > 0) {
      setGenerateRequirementLoading(true);
      response = await generateRequirement(
        docId,
        pageNumber,
        authInfo.accessToken ?? ""
      );
      if (response !== null && response.length === 0) {
        toast.info("No requirement to suggest");
      }
      setGenerateRequirementLoading(false);
    }
    let newRequirement: Requirement;

    // if the response is not null, use the first requirement in the response otherwise create a new one
    if (response !== null && response.length > 0) {
      newRequirement = response[0];
      newRequirement.id = "NEW";
      newRequirement.citations[0].id = HIGHLIGHTED_CITATION_ID;
    } else {
      newRequirement = {
        id: "NEW",
        text: "",
        reference_doc_types: activeRequirement?.reference_doc_types ?? null,
        citations: [
          {
            id: HIGHLIGHTED_CITATION_ID,
            page: pageNumber,
            text: "",
            formatted_text: null,
            start_index: null,
            doc_id: docId,
            doc_name: "",
            created_at: new Date().toISOString().slice(0, -1),
            user: {
              id: authInfo.user?.userId,
              first_name: authInfo.user?.firstName,
              last_name: authInfo.user?.lastName,
              email: authInfo.user?.email,
            } as SimpleUser,
          } as Citation,
        ],
        action_items: [],
        tag: null,
        impacted_documents: [],
        assignees: [],
        updated_at: new Date().toISOString().slice(0, -1),
        reference_documents: [],
      };
    }
    setRequirements([...requirements, newRequirement]);
    setActiveRequirementId("NEW");
    setEditing(true);
  };

  return (
    <RequirementContext.Provider
      value={{
        requirements,
        setRequirements,
        loadRequirements,
        addRequirement,
        generateRequirementLoading,
        requirementsLoading,
        viewType,
        setViewType,
        activeRequirement,
        editing,
        setEditing,
        editingIndividualRequirementCitation,
        setEditingIndividualRequirementCitation,
        activeRequirementId,
        setActiveRequirementId,
        individualRequirementActiveCitationIndex,
        setIndividualRequirementActiveCitationIndex,
      }}
    >
      {children}
    </RequirementContext.Provider>
  );
};
