import { useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import { getFilterDocTypes } from "../../utils/apiCalls";
import { toast } from "sonner";
import { useAuthInfo } from "@propelauth/react";
import { DocType } from "../../types";
import { useCookies } from "react-cookie";
import { RegulatoryDocTableView } from "./RegulatoryDocTableView";
import { RegulatoryDocFeedView } from "./RegulatoryDocFeedView";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { Button } from "../../shadcn/components/button";
import { ListBulletIcon, TableIcon } from "@radix-ui/react-icons";
import { SearchDocNameBar } from "../../components/SearchDocName";
import { useNavigate } from "react-router-dom";

const RegluatoryDocViewViewToggle = (props: {
  viewType: "list" | "table";
  setViewType: (viewType: "list" | "table") => void;
}) => {
  return (
    <div className="flex items-center space-x-1 bg-gray-200 p-1 rounded-md">
      <Tooltip>
        <TooltipTrigger>
          <Button
            size="icon"
            variant={props.viewType === "list" ? "outline" : "ghost"}
            onClick={() => {
              if (props.viewType !== "list") {
                props.setViewType("list");
              }
            }}
          >
            <ListBulletIcon className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>View Regulatory Guidance as a List</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger>
          <Button
            size="icon"
            variant={props.viewType === "table" ? "outline" : "ghost"}
            onClick={() => {
              if (props.viewType !== "table") {
                props.setViewType("table");
              }
            }}
          >
            <TableIcon className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>View Regulatory Guidance as a Table</TooltipContent>
      </Tooltip>
    </div>
  );
};

export const RegulatoryDocSummaryView = () => {
  const authInfo = useAuthInfo();
  const [agencyFilters, setAgencyFilters] = useState<string[]>([]);
  const [statusFilters, setStatusFilters] = useState<string[]>([]);
  const [relevantFilters, setRelevantFilters] = useState<string[]>([]);
  const [docTypes, setDocTypes] = useState<DocType[]>([]);
  const [cookies, setCookies] = useCookies([
    "reg-feed-filter",
    "relevant-filter",
    "reg-feed-view-type",
    "reg-feed-status-filter",
  ]);
  const [viewType, setViewType] = useState<"list" | "table">(
    cookies["reg-feed-view-type"] ?? "list"
  );
  const navigate = useNavigate();

  useEffect(() => {
    setCookies("reg-feed-filter", agencyFilters);
  }, [agencyFilters]);

  useEffect(() => {
    setCookies("reg-feed-view-type", viewType);
  }, [viewType]);

  useEffect(() => {
    setCookies("reg-feed-status-filter", statusFilters);
  }, [statusFilters]);

  useEffect(() => {
    getFilterDocTypes(authInfo.accessToken ?? null).then((docTypes) => {
      if (docTypes !== null) {
        setDocTypes(docTypes);
        if (
          cookies["reg-feed-filter"] &&
          cookies["reg-feed-filter"].length > 0
        ) {
          setAgencyFilters(cookies["reg-feed-filter"]);
        } else {
          setAgencyFilters(docTypes.map((docType) => docType.id));
        }
      } else {
        toast.error("Something went wrong fetching filter doc types");
      }
    });
    setRelevantFilters(cookies["relevant-filter"] ?? []);
  }, []);

  return (
    <Layout pageName="Regulatory Guidance">
      <div className="space-y-1">
        <div className="flex items-center space-x-2 justify-between">
          <SearchDocNameBar
            docTypeIds={docTypes.map((docType) => docType.id)}
            placeholder="Search Regulations..."
            onItemSelect={(result) => {
              navigate(
                `/regulatory-doc/overview/${result.additional_metadata["regulatory_doc_id"]}`
              );
            }}
            hideAtlasWidget={true}
          />
          <RegluatoryDocViewViewToggle
            viewType={viewType}
            setViewType={setViewType}
          />
        </div>
        {viewType === "list" ? (
          <RegulatoryDocFeedView
            agencyFilters={agencyFilters}
            relevantFilters={relevantFilters}
            docTypes={docTypes}
            setAgencyFilters={setAgencyFilters}
            setRelevantFilters={setRelevantFilters}
            setCookies={setCookies}
          />
        ) : (
          <RegulatoryDocTableView
            agencyFilter={agencyFilters}
            setAgencyFilter={setAgencyFilters}
            relevantFilters={relevantFilters}
            setRelevantFilters={setRelevantFilters}
            docTypes={docTypes}
            setCookies={setCookies}
            statusFilters={statusFilters}
            setStatusFilters={setStatusFilters}
          />
        )}
      </div>
    </Layout>
  );
};
