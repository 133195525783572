import { useContext } from "react";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { Separator } from "../../shadcn/components/separator";
import { HighlightTooltipActionButton } from "../../components/HighlightTooltipActionButton";
import { toast } from "sonner";

export const BaseActions = (props: {
  setMessage: (message: string) => void;
  setActiveTab: (tab: string) => void;
}) => {
  const { setCitations, docToView, citations } = useContext(DocViewerContext);
  return (
    <div className="w-48">
      <HighlightTooltipActionButton
        text="Clarify"
        onClick={async () => {
          if (citations.length && citations[0]?.match && docToView?.docId) {
            props.setActiveTab("chat");
            props.setMessage(
              `<messageType>clarify</messageType><relevantText>${citations[0].match}</relevantText>`
            );
          }
        }}
        explanationContent="Clarify the highlighted section in simple language"
      />
      <HighlightTooltipActionButton
        text="Define"
        onClick={async () => {
          if (citations.length && citations[0]?.match && docToView?.docId) {
            props.setActiveTab("chat");
            props.setMessage(
              `<messageType>definition</messageType><relevantText>${citations[0].match}</relevantText>`
            );
          }
        }}
        explanationContent="Define of the highlighted term in the document"
      />
      <HighlightTooltipActionButton
        text="Impacted Policies"
        onClick={async () => {
          if (citations.length && citations[0]?.match && docToView?.docId) {
            props.setActiveTab("chat");
            props.setMessage(
              `<messageType>impacted_policies</messageType><relevantText>${citations[0].match}</relevantText>`
            );
          }
        }}
        explanationContent="Find the policies impacted by the highlighted section"
      />
      <HighlightTooltipActionButton
        text="Reference Document"
        onClick={async () => {
          if (citations.length && citations[0]?.match && docToView?.docId) {
            props.setActiveTab("chat");
            props.setMessage(
              `<messageType>linked_document</messageType><relevantText>${citations[0].match}</relevantText>`
            );
          }
        }}
        explanationContent="Find the relevant sections in the highlighted documents"
      />
      <Separator />
      {citations.length && citations[0]?.match && (
        <HighlightTooltipActionButton
          text="Copy"
          onClick={async () => {
            await navigator.clipboard.writeText(citations[0].match);
            toast.success("Copied to clipboard");
          }}
          explanationContent="Copy the highlighted text to clipboard"
        />
      )}
      <HighlightTooltipActionButton
        text="Clear"
        onClick={async () => {
          setCitations([]);
        }}
        explanationContent="Clear the highlighted text"
      />
    </div>
  );
};
