import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  ReactNode,
  forwardRef,
  useImperativeHandle,
} from "react";
import { usePdf } from "./PdfContext"; // Adjust the import based on your project structure

interface PdfLazyLoaderProps {
  numPages: number;
  computeRowHeight: (index: number) => number;
  renderPage: (pageNum: number) => ReactNode;
  overscan: number;
}
export interface PdfLazyLoaderRef {
  scrollToPage: (page: number) => void;
  resetScale: () => void;
}

const PdfLazyLoader = forwardRef<PdfLazyLoaderRef, PdfLazyLoaderProps>(
  (
    { numPages, computeRowHeight, renderPage, overscan }: PdfLazyLoaderProps,
    ref
  ) => {
    const loaderPageRefs = useRef<Map<number, HTMLDivElement>>(new Map());
    const loaderContainerRef = useRef<HTMLDivElement>(null);
    const isInitialScrollComplete = useRef<boolean>(false);
    const [forceRerender, setForceRerender] = useState<boolean>(false);
    const {
      documentStatus,
      currentPage,
      setCurrentPage,
      containerRef,
      setScrollOffset,
      loadedPageStartIndex,
      loadedPageEndIndex,
      setLoadedPageStartIndex,
      setLoadedPageEndIndex,
    } = usePdf();

    useImperativeHandle(ref, () => ({
      scrollToPage,
      resetScale,
    }));

    const resetScale = () => {
      setForceRerender((prev) => !prev);
    };

    const scrollToPage = (page: number) => {
      const pageElement = loaderPageRefs.current.get(page);
      if (pageElement) {
        pageElement.scrollIntoView({ block: "start" });
        handleScroll();
      }
    };
    useEffect(() => {
      const container = loaderContainerRef.current;
      if (!container) return;
      const observerOptions = {
        root: container,
        rootMargin: "-50% 0px -50% 0px",
        threshold: 0,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const pageIndex = Number(
            entry.target.getAttribute("data-page-number")
          );
          if (!isInitialScrollComplete.current && currentPage) {
            if (pageIndex === currentPage) {
              isInitialScrollComplete.current = true;
              setCurrentPage(pageIndex);
              setLoadedPageStartIndex(Math.max(1, pageIndex - overscan));
              setLoadedPageEndIndex(Math.min(numPages, pageIndex + overscan));
              scrollToPage(pageIndex);
            }
          } else if (entry.isIntersecting) {
            setCurrentPage(pageIndex);
            setLoadedPageStartIndex(Math.max(1, pageIndex - overscan));
            setLoadedPageEndIndex(Math.min(numPages, pageIndex + overscan));
          }
        });
      }, observerOptions);

      // Observe all page containers
      for (let i = 1; i <= numPages; i++) {
        const pageElement = loaderPageRefs.current?.get(i);
        if (pageElement) {
          observer.observe(pageElement);
        }
      }

      return () => {
        observer.disconnect();
      };
    }, [
      numPages,
      currentPage,
      containerRef,
      loaderPageRefs.current,
      documentStatus,
      forceRerender,
    ]);

    const handleScroll = () => {
      const container = loaderContainerRef.current;
      if (container) {
        setScrollOffset(container.scrollTop);
      }
    };

    // Render the pages based on the visible pages
    return (
      <div
        ref={loaderContainerRef}
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
          overflowY: "scroll",
        }}
        onScroll={handleScroll}
      >
        {[...Array(numPages)].map((_, index) => (
          <div
            key={`${index}-${forceRerender}`}
            style={{
              height: `${computeRowHeight(index)}px`,
              position: "relative",
            }}
            data-page-number={index + 1}
            ref={(ref) => {
              if (ref && loaderPageRefs)
                loaderPageRefs.current?.set(index + 1, ref);
            }}
          >
            {index + 1 >= loadedPageStartIndex &&
              index + 1 <= loadedPageEndIndex &&
              renderPage(index + 1)}
          </div>
        ))}
      </div>
    );
  }
);

export default PdfLazyLoader;
