import { Requirement } from "../../types";
import { MarkdownRedlineDisplay } from "../MarkdownDisplay";

const closeOpenTags = (text: string) => {
  const openStrikethrough =
    (text.match(/<strikethrough>/g) || []).length >
    (text.match(/<\/strikethrough>/g) || []).length;
  const openUnderline =
    (text.match(/<underline>/g) || []).length >
    (text.match(/<\/underline>/g) || []).length;

  return (
    text +
    (openStrikethrough ? "</strikethrough>" : "") +
    (openUnderline ? "</underline>" : "")
  );
};

export const RequirementTextDisplay = (props: {
  text: string;
  maxWords?: number;
}) => {
  const words = props.text.split(" ");
  const endEllipsis =
    props.maxWords && words.length > props.maxWords ? "..." : "";
  const truncatedText = words.slice(0, props.maxWords).join(" ");
  const finalText = closeOpenTags(truncatedText) + endEllipsis;

  return <MarkdownRedlineDisplay text={finalText} />;
};

export const RequirementDisplay = (props: { requirement: Requirement }) => {
  return (
    <div className="flex">
      <span className="text-gray-400 whitespace-nowrap mr-2">
        pg {props.requirement.citations[0]?.page ?? 1} |
      </span>
      <RequirementTextDisplay text={props.requirement.text} maxWords={20} />
    </div>
  );
};
