import { useAuthInfo } from "@propelauth/react";
import { Layout } from "../../components/Layout";
import { useNavigate } from "react-router-dom";
import { getAudits } from "../../utils/apiCalls";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { Audit } from "../../types";
import { Input } from "../../shadcn/components/input";
import { LoadingView } from "../../components/Loading";

export const AuditView = () => {
  const authInfo = useAuthInfo();
  const [audits, setAudits] = useState<Audit[]>([]);
  const [auditsLoading, setAuditsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const auditsToDisplay = audits.filter(
    (audit) =>
      audit.name.toLowerCase().includes(search.toLowerCase()) || search === ""
  );

  useEffect(() => {
    setAuditsLoading(true);
    getAudits(authInfo.accessToken ?? null).then((audits) => {
      if (audits) {
        setAudits(audits);
      } else {
        toast.error("Failed to fetch audits");
      }
      setAuditsLoading(false);
    });
  }, []);

  return (
    <Layout pageName="Audits">
      <div className="flex justify-center space-y-2">
        <Input
          className="w-[600px]"
          placeholder="Search audits"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="flex justify-center space-y-2">
        {auditsLoading && <LoadingView customText="Loading audits..." />}
        <div className="flex flex-col space-y-2">
          {auditsToDisplay.map((audit) => (
            <div
              key={audit.id}
              className="bg-white w-[600px] p-5 rounded-md hover:bg-gray-200 cursor-pointer"
            >
              <a href={`/audit/${audit.id}`}>
                <div className="text-md font-bold">{audit.name}</div>
              </a>
            </div>
          ))}
        </div>
        {auditsToDisplay.length === 0 && !auditsLoading && (
          <div className="text-center text-gray-500">No resources found</div>
        )}
      </div>
    </Layout>
  );
};
