import React, {
  MutableRefObject,
  RefObject,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CitationText, DocMetadata, DocToView } from "../types";
import { useAuthInfo } from "@propelauth/react";
import { toast } from "sonner";
import { getDocMetadata } from "../utils/apiCalls";
import { PdfCitation } from "../components/PdfViewer/PdfHighlighter/types";

interface DocViewerContextProps {
  docToView: DocToView | null;
  setDocToView: React.Dispatch<React.SetStateAction<DocToView | null>>;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  numPages: number | null;
  setNumPages: React.Dispatch<React.SetStateAction<number | null>>;
  citations: PdfCitation[];
  setCitations: React.Dispatch<React.SetStateAction<PdfCitation[]>>;
  rotation: number;
  setRotation: React.Dispatch<React.SetStateAction<number>>;
  docMetadata: DocMetadata | null;
  setDocMetadata: React.Dispatch<React.SetStateAction<DocMetadata | null>>;
  scrollBoxRef: MutableRefObject<HTMLDivElement | null>;
  setScrollBoxRef: (ref: HTMLDivElement | null) => void;
  paginatePDF: boolean;
  setPaginatePDF: (paginatePdf: boolean) => void;
}

export const DocViewerContext = createContext<DocViewerContextProps>({
  docToView: null,
  setDocToView: () => {},
  pageNumber: 1,
  setPageNumber: () => {},
  numPages: null,
  setNumPages: () => {},
  citations: [],
  setCitations: () => {},
  rotation: 0,
  setRotation: () => {},
  docMetadata: null,
  setDocMetadata: () => {},
  scrollBoxRef: { current: null },
  setScrollBoxRef: (ref) => {},
  paginatePDF: true,
  setPaginatePDF: () => {},
});

export const DocViewerProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const authInfo = useAuthInfo();
  const [docToView, setDocToView] = useState<DocToView | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [citations, setCitations] = useState<CitationText[]>([]);
  const [rotation, setRotation] = useState<number>(0);
  const [docMetadata, setDocMetadata] = useState<DocMetadata | null>(null);
  const [paginatePDF, setPaginatePDF] = useState<boolean>(true);
  const scrollBoxRef = useRef<HTMLDivElement | null>(null);

  const setScrollBoxRef = (ref: HTMLDivElement | null) => {
    scrollBoxRef.current = ref;
  };

  useEffect(() => {
    if (docToView?.docId) {
      getDocMetadata(docToView.docId, authInfo.accessToken ?? null).then(
        (metadata) => {
          if (metadata !== null) {
            setDocMetadata(metadata);
          } else {
            toast.error("Failed to load document metadata");
          }
        }
      );
    }
  }, [docToView?.docId]);

  return (
    <DocViewerContext.Provider
      value={{
        docToView,
        setDocToView,
        pageNumber,
        setPageNumber,
        numPages,
        setNumPages,
        citations,
        setCitations,
        rotation,
        setRotation,
        docMetadata,
        setDocMetadata,
        scrollBoxRef,
        setScrollBoxRef,
        paginatePDF,
        setPaginatePDF,
      }}
    >
      {children}
    </DocViewerContext.Provider>
  );
};
