import React, { useEffect, useState } from "react";
import { CitationHighlight } from "./types";
import { usePdfHighlighter } from ".";
import { usePdf } from "../PdfContext";
import { HighlightTooltip } from "./HighlightTooltip";

type HighlightLayerProps = {
  page: number;
};
export const HighlightLayer: React.FC<HighlightLayerProps> = ({ page }) => {
  const { citations } = usePdf();
  const { rangeHighlightsRef, onHighlightLayerRender, isHighlighting } =
    usePdfHighlighter();
  const [currentCitations, setCurrentCitations] = useState<CitationHighlight[]>(
    []
  );
  useEffect(() => {
    const highlights = onHighlightLayerRender(page);
    setCurrentCitations(highlights);
  }, []);

  useEffect(() => {
    const highlights = onHighlightLayerRender(page);
    setCurrentCitations(highlights);
  }, [citations]);

  return (
    <div className={`PDF__highlight__div__layer page__${page}`}>
      {currentCitations.map((citation) => (
        <React.Fragment key={`${citation.id}`}>
          <CitationDisplay key={`ct-${citation.id}`} citaiton={citation} />
          <HighlightTooltip
            key={`tt-${citation.id}`}
            isVisible={!!citation.match.length}
            highlightBox={citation.boundingRect}
            pageNum={page}
            toolTipClass={citation.tooltipClassName || ""}
          >
            <div>{citation.citationTooltip}</div>
          </HighlightTooltip>
        </React.Fragment>
      ))}
      {rangeHighlightsRef?.current &&
        rangeHighlightsRef.current.page === page && (
          <CitationDisplay citaiton={rangeHighlightsRef.current} />
        )}
    </div>
  );
};

type CitationDisplayProps = {
  citaiton: CitationHighlight;
};
export const CitationDisplay: React.FC<CitationDisplayProps> = ({
  citaiton: citation,
}) => {
  const [hover, sethover] = useState<boolean>(false);
  return (
    <div className={`PDF__highlight__container`} data-citation-id={citation.id}>
      {citation.highlightBoxes.map((box) => {
        return (
          <div
            key={`hlb-${box.top}-${box.left}`}
            className={`PDF__highlight__box ${citation.isSelected ? "highlight__selected" : ""} ${hover ? citation.classNameOnHover : citation.className}`}
            style={{
              top: `${box.top}px`,
              left: `${box.left}px`,
              width: `${box.width}px`,
              height: `${box.height}px`,
              pointerEvents:
                typeof citation.onClick === "function" ? "all" : "none",
              cursor: `${typeof citation.onClick === "function" ? "pointer" : "auto"}`,
            }}
            onClickCapture={(e) => {
              if (typeof citation.onClick === "function") {
                citation.onClick();
              }
            }}
            onMouseEnter={() => {
              sethover(true);
            }}
            onMouseLeave={() => sethover(false)}
          ></div>
        );
      })}
    </div>
  );
};
