import { ReloadIcon } from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../shadcn/components/tooltip";
import { useContext, useState } from "react";
import { toast } from "sonner";
import { DocViewerContext } from "../contexts/DocViewerContext";
import { PdfCitation } from "./PdfViewer/PdfHighlighter/types";

export const HighlightTooltipActionButton = (props: {
  text: string;
  explanationContent: string;
  onClick: () => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await props.onClick();
    setLoading(false);
  };

  return (
    <div className="overflow-hidden rounded-md border bg-popover text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2">
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            className="w-full hover:bg-accent hover:text-accent-foreground text-sm text-left py-2 pl-8 flex items-center"
            onClick={handleClick}
          >
            {props.text}
            {loading && <ReloadIcon className="w-4 h-4 ml-2 animate-spin" />}
          </div>
        </TooltipTrigger>
        <TooltipContent side="right">{props.explanationContent}</TooltipContent>
      </Tooltip>
    </div>
  );
};

export const BaseHighlightTooltip = (props: {
  citationText: string;
  citationId: string;
}) => {
  const { setCitations } = useContext(DocViewerContext);

  return (
    <div className="w-48">
      <HighlightTooltipActionButton
        text="Copy"
        onClick={async () => {
          await navigator.clipboard.writeText(props.citationText);
          toast.success("Copied to clipboard");
        }}
        explanationContent="Copy the highlighted text to clipboard"
      />
      <HighlightTooltipActionButton
        text="Clear"
        onClick={async () => {
          // remove the citation
          setCitations((prev) => prev.filter((c) => c.id !== props.citationId));
        }}
        explanationContent="Clear the highlighted text"
      />
    </div>
  );
};
