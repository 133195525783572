import "./assets/globals.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { RequiredAuthProvider, RedirectToLogin } from "@propelauth/react";

// Import page components
import { ServicesProviderWrapper } from "./components/ServicesProviderWrapper";
import { SearchPage } from "./pages/search/SearchView";
import { SettingsPage } from "./pages/settings/SettingsView";
import { Toaster } from "sonner";
import { TooltipProvider } from "./shadcn/components/tooltip";
import { DocChatView } from "./pages/doc-chat/DocChatView";
import { RegulatoryDocView } from "./pages/regulatory_docs/RegulatoryDocView";
import { AuditAnswerReviewView } from "./pages/audit/QuestionReviewView";
import { AuditQuestionView } from "./pages/audit/QuestionView";
import { AuditView } from "./pages/audit/AuditView";
import { InsightView } from "./pages/insight/InsightView";
import { InsightTableView } from "./pages/insight/InsightTableView";
import { DocViewerProvider } from "./contexts/DocViewerContext";
import { UserProvider } from "./contexts/UserContext";
import { PolicyRepositoryDocView } from "./pages/policy_repository/PolicyRepositoryDocView";
import { PolicyRepositoryOverviewView } from "./pages/policy_repository/PolicyRepositoryOverviewView";
import { GapAnalysisView } from "./pages/gap-analysis/GapAnalysisView";
import { GapAnalysisOverviewView } from "./pages/gap-analysis/GapAnalysisOverview";
import { ModalProvider } from "./contexts/ActiveModalContext";
import { RequirementProvider } from "./contexts/RequirementContext";
import { AuditResourceView } from "./pages/audit/AuditResourceView";
import { AuditUploadView } from "./pages/audit/AuditUploadView";
import { DashboardRouter } from "./pages/dashboard/DashboardRouter";
import { RegulatoryDocSummaryView } from "./pages/regulatory_docs/RegulatoryDocSummaryView";

const container = document.getElementById("root");
const root = createRoot(container!);

export const App = () => {
  return (
    <ServicesProviderWrapper>
      <TooltipProvider delayDuration={0}>
        <BrowserRouter>
          <UserProvider>
            <DocViewerProvider>
              <ModalProvider>
                <Routes>
                  <Route
                    path="/audit/:auditId/:auditResourceId/question/:questionId/answer/:answerId"
                    element={<AuditAnswerReviewView />}
                  />
                  <Route
                    path="/audit/:auditId/questionnaire/:auditResourceId"
                    element={<AuditQuestionView />}
                  />
                  <Route
                    path="/audit/:auditId/upload/:auditResourceId"
                    element={<AuditUploadView />}
                  />
                  <Route
                    path="/audit/:auditId/table/:auditResourceId"
                    element={<AuditUploadView />}
                  />
                  <Route
                    path="/audit/:auditId"
                    element={<AuditResourceView />}
                  />
                  <Route path="/audit" element={<AuditView />} />
                  <Route path="/insight" element={<InsightView />} />
                  <Route
                    path="/insight/:insightId"
                    element={<InsightTableView />}
                  />
                  <Route
                    path="/regulatory-doc"
                    element={<RegulatoryDocSummaryView />}
                  />
                  <Route
                    path="/regulatory-doc/overview/:regulatoryDocId"
                    element={
                      <RequirementProvider>
                        <RegulatoryDocView />
                      </RequirementProvider>
                    }
                  />
                  <Route
                    path="/gap-analysis/:gapAnalysisId"
                    element={
                      <RequirementProvider>
                        <GapAnalysisOverviewView />
                      </RequirementProvider>
                    }
                  />
                  <Route path="/gap-analysis" element={<GapAnalysisView />} />
                  <Route path="/dashboard" element={<DashboardRouter />} />
                  <Route
                    path="/policy-repo/doc-view/:docId"
                    element={<PolicyRepositoryDocView />}
                  />
                  <Route
                    path="/policy-repo"
                    element={<PolicyRepositoryOverviewView />}
                  />
                  <Route path="/doc-chat" element={<DocChatView />} />
                  <Route path="/search" element={<SearchPage />} />
                  <Route path="/settings" element={<SettingsPage />} />
                  <Route path="/" element={<Navigate replace to="/search" />} />
                </Routes>
              </ModalProvider>
            </DocViewerProvider>
          </UserProvider>
          <Toaster richColors />
        </BrowserRouter>
      </TooltipProvider>
    </ServicesProviderWrapper>
  );
};

const authUrl = import.meta.env.VITE_AUTH_URL;

root.render(
  <RequiredAuthProvider
    // authUrl={process.env.AUTH_URL}
    authUrl={authUrl}
    // displayWhileLoading={<Loading />}
    displayIfLoggedOut={<RedirectToLogin />}
  >
    <App />
  </RequiredAuthProvider>
);
